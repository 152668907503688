import React from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { clearTemplate, getEditBlocks, seveTamlate } from '../store/editor/actions';
import { TEditBlock } from '../store/editor/types';
import { useManageBlocks } from '../components/hooks/useManageBlocks';
import { EditorHeader } from '../components/Editor/HeaderEditor/EditorHeader';
import { SidebarEditor } from '../components/Editor/SidebarEditor/SidebarEditor';
import { BodyEditor } from '../components/Editor/BodyEditor/BodyEditor';
import { AppStore } from '../store/applicationState';
import { btnReplace } from '../utils/btnReplace';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export const Editor: React.FC = () => {
  const {
    draggedBlocks,
    isMove,
    isSiteBar,
    isDrop,
    copyDraggedBlock,
    handleeventDrop,
    removeDraggedBlock,
    changeBlock,
    startDraggedBlock,
    setCurrentBlock,
    setIsMove,
    setIsDrop,
    setIsSiteBar,
    setDraggedBlocks,
    addBlock,
  } = useManageBlocks();

  const [id, setId] = React.useState<number | null>(null);
  const [clientWigth, setClientWigth] = React.useState<number | null>(null);
  const [scrollTop, setScrollTop] = React.useState<number | null>(null);
  const refOnScroll = React.useRef<HTMLDivElement | null>(null);
  const refOnClientWidth = React.useRef<HTMLDivElement | null>(null);

  const classes = useStyles();
  const { Edit } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (refOnScroll.current) {
      setScrollTop(refOnScroll.current.scrollTop);
    }
  }, [refOnScroll, isMove]);

  React.useEffect(() => {
    if (refOnClientWidth.current) {
      setClientWigth(refOnClientWidth.current.clientWidth);
    }
  }, [refOnClientWidth]);

  React.useEffect(() => {
    const idW = window.location.hash.replace('#/editor/', '');
    const num = Number(idW);
    const id = num ? (Number.isNaN(num) ? null : num) : null;
    if (id) {
      setId(id);
    }
  }, []);

  React.useEffect(() => {
    if (id) {
      dispatch(getEditBlocks.request(id));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    const template = Edit.template;
    if (template) {
      const blocks = [] as TEditBlock[];
      const strSplit = '<table width="100%" cellpadding="0" cellspacing="0" data-module=';
      template.split(strSplit).forEach((item, idx) => {
        const text = item.split(' ').join('').split('\t').join('').split('\n').join('').split('\r').join('');
        if (!text) return null;
        const html = btnReplace(item);
        const block = {
          id: item.length,
          sort: idx,
          mag: 'test',
          name: 'test',
          html: strSplit + html,
          changePalces: true,
        };
        blocks.push(block);
      });
      setDraggedBlocks(blocks);
      dispatch(clearTemplate(null));
    }
  }, [dispatch, setDraggedBlocks, Edit.template]);

  const saveTampalte = React.useCallback(
    (id: number) => {
      const html = draggedBlocks
        ? draggedBlocks
            .map((item) => {
              let cleanHtml = item.html.replace(' class="currentTable"', '');
              cleanHtml = cleanHtml.replace(
                '<table width="100%" cellspacing="0" cellpadding="0" data-module=',
                '<table width="100%" cellpadding="0" cellspacing="0" data-module='
              );
              cleanHtml = cleanHtml.replace(/\u200B/g, '');
              return cleanHtml;
            })
            .join('')
        : '';
      const template = Buffer.from(html).toString('base64');
      dispatch(seveTamlate.request({ id, template }));
    },
    [dispatch, draggedBlocks]
  );

  return (
    <div ref={refOnClientWidth} className={classes.root} style={{ height: '100vh' }}>
      <CssBaseline />
      <EditorHeader id={id} saveTampalte={saveTampalte} />
      <SidebarEditor
        blocks={Edit.editBlocks}
        clientWigth={clientWigth}
        isMove={isMove}
        isDrop={isDrop}
        setIsSiteBar={setIsSiteBar}
        setIsDrop={setIsDrop}
        setIsMove={setIsMove}
        setCurrentBlock={setCurrentBlock}
        addBlock={addBlock}
      />
      <main
        ref={refOnScroll}
        //@ts-ignore
        onScroll={(e) => isMove && !isSiteBar && setScrollTop(e.target.scrollTop)}
        className={classes.content}
      >
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <BodyEditor
            draggedBlocks={draggedBlocks}
            isMove={isMove}
            isSiteBar={isSiteBar}
            scrollTop={scrollTop}
            setScrollTop={setScrollTop}
            changeBlock={changeBlock}
            copyDraggedBlock={copyDraggedBlock}
            handleeventDrop={handleeventDrop}
            removeDraggedBlock={removeDraggedBlock}
            startDraggedBlock={startDraggedBlock}
            setDraggedBlocks={setDraggedBlocks}
            setIsDrop={setIsDrop}
            setIsMove={setIsMove}
          />
        </Container>
      </main>
    </div>
  );
};
