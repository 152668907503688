import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import CodeIcon from '@material-ui/icons/Code';
import { Typography } from '@material-ui/core';
import { AccordionDefault } from './ui';
import { SideBarStyles } from './SideBarStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CreateIcon } from './svgs/IconCreate';
import SvgIcon from '@material-ui/core/SvgIcon';

interface SidebarProps {
  open: boolean;
  closeDrawer: (value: boolean) => void;
}

export const Sidebar: React.FC<SidebarProps> = React.memo(({ open, closeDrawer }) => {
  const classes = SideBarStyles();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.header}>
        <h2 className={classes.titleBlock}>Вид рассылки</h2>
        <IconButton edge="start" color="inherit" aria-label="open drawer" style={{ marginRight: '8px' }} onClick={() => closeDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <ListItem>
        <AccordionDefault />
      </ListItem>
      <h2 className={classes.titleBlock}>Обозначения</h2>
      <ListItem>
        <Box display="flex" alignItems="center">
          <SvgIcon fontSize="small">
            <CreateIcon />
          </SvgIcon>
          <Typography style={{ marginLeft: '15px' }}>планируется</Typography>
        </Box>
      </ListItem>{' '}
      <ListItem>
        <Box display="flex" alignItems="center">
          <CodeIcon fontSize="small" />
          <Typography style={{ marginLeft: '15px' }}>верстка</Typography>
        </Box>
      </ListItem>{' '}
      <ListItem>
        <Box display="flex" alignItems="center">
          <SearchIcon fontSize="small" />
          <Typography style={{ marginLeft: '15px' }}>тест</Typography>
        </Box>
      </ListItem>{' '}
      <ListItem>
        <Box display="flex" alignItems="center">
          <ThumbUpAltOutlinedIcon fontSize="small" />
          <Typography style={{ marginLeft: '15px' }}>согласована</Typography>
        </Box>
      </ListItem>{' '}
      <ListItem>
        <Box display="flex" alignItems="center">
          <CheckIcon fontSize="small" />
          <Typography style={{ marginLeft: '15px' }}>отправлено</Typography>
        </Box>
      </ListItem>
    </Drawer>
  );
});
