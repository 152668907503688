import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Copyright } from '../components/Copyright';
import { Header } from '../components/Header';
import { useFilter } from '../components/hooks/useFilter';
import { MainCalendar } from '../components/MainCalendar';
import { Sidebar } from '../components/Sidebar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // height: '100vh',
    // overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export const Home: React.FC = () => {
  const [isSidebar, setIsSidebar] = React.useState(true);
  const [isRender, setIsRender] = React.useState(true);
  const { initialDate } = useFilter();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title="Головна" open={isSidebar} openDrawer={setIsSidebar} onRender={() => setIsRender(!isRender)} />
      <Sidebar open={isSidebar} closeDrawer={setIsSidebar} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} style={{ maxWidth: '100%' }}>
            <MainCalendar initialDate={initialDate} />
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};
