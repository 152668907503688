import React from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import 'moment/locale/ru';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: '#fff',
    color: '#000',
    zIndex: 100,
    padding: '20px',
    boxShadow: '0 0 10px rgba($color: #000000, $alpha: 0.2)',
    borderRadius: '4px',
    width: '400px',
    margin: 'auto',
    bottom: '50%',
    right: '50%',
    transform: 'translate(50%, 50%)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
    cursor: 'pointer',
    fontSize: '18px',
  },
  week: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  days: {
    width: '60px',
    height: '60px',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  wrapBtn: {
    display: 'flex',
    justifyContent: 'space-around',
    cursor: 'pointer',
  },
  btn: {
    minWidth: '20px',
    padding: '10px 13px',
    borderRadius: '20px',
    '& > *': {
      '&:nth-child(1)': {
        width: '15px',
      },
    },
  },
}));

export interface MultiDatesPickerProps {
  open: boolean;
  handleClose: () => void;
  onDone: (value: Date[] | []) => void;
}

export const MultiDatesPicker: React.FC<MultiDatesPickerProps> = ({ open, handleClose, onDone }) => {
  const [date, setDate] = React.useState(new Date());
  const [currentDay] = React.useState(new Date().getDate());
  const [currentMounth] = React.useState(new Date().getMonth());
  const [mounth, setMounth] = React.useState<number>(new Date().getMonth());
  const [mapDays, setMapDays] = React.useState<number[][] | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<any | null>(null);

  const calsses = useStyles();

  React.useEffect(() => {
    if (date) {
      setMapDays(generateMapDays(date));
    }
  }, [date]);

  const generateMapDays = (date: Date) => {
    const dayWeek = new Date(moment(date).startOf('month').format('YYYY-MM-DD')).getDay();
    const daysInMonth = moment(date).daysInMonth();
    const days = [];
    let i = 0;
    let day = 1;
    let week = 0;
    let daysOfWeek = 7;
    let dayOfWeek = dayWeek - 1;
    let start = true;

    while (i < daysInMonth) {
      if (daysOfWeek === dayOfWeek) {
        week++;
        dayOfWeek = 0;
        days.push([]);
      }
      if (start) {
        days.push([day]);
        start = false;
      } else {
        days[week].push(day);
      }
      day++;
      dayOfWeek++;
      i++;
    }

    return days.map((item, index) => {
      if ((item.length < 7 && index === 0) || index === days.length - 1) {
        const diff = 7 - item.length;
        const outData = [];
        let i = 0;
        while (i < diff) {
          outData.push(0);
          i++;
        }
        if (index === 0) {
          return [...outData, ...item];
        } else {
          return [...item, ...outData];
        }
      }
      return item;
    });
  };

  const selectDate = (key: string, mounth: number, day: number) => {
    const obj = {};
    const date = new Date(new Date(new Date().setMonth(mounth)).setDate(day));
    if (selectedDate) {
      if (!selectedDate[key]) {
        //@ts-ignore
        obj[key] = date;
        setSelectedDate({ ...selectedDate, ...obj });
      } else {
        delete selectedDate[key];
        setSelectedDate({ ...selectedDate });
      }
    } else {
      //@ts-ignore
      obj[key] = date;
      setSelectedDate(obj);
    }
  };

  const nextMounth = () => {
    const mounth = date.getMonth() + 1;
    setDate(new Date(date.setMonth(mounth)));
    setMounth(mounth);
    if (mounth === 12) {
      setMounth(0);
    } else {
      setMounth(mounth);
    }
  };

  const prevMounth = () => {
    const mounth = date.getMonth() - 1;
    setDate(new Date(date.setMonth(mounth)));
    if (mounth === -1) {
      setMounth(11);
    } else {
      setMounth(mounth);
    }
  };

  const sendData = () => {
    let data = [] as Date[];
    if (selectedDate) {
      data = Object.values(selectedDate);
    }
    onDone(data);
    handleClose();
  };

  const checkVariant = (day: number, key: string) => {
    if (selectedDate && selectedDate[key]) {
      return 'contained';
    }
    if (day === currentDay && currentMounth === mounth) {
      return 'outlined';
    }
    return 'text';
  };

  const checkColor = (day: number, key: string) => {
    if ((selectedDate && selectedDate[key]) || (day === currentDay && currentMounth === mounth)) {
      return 'primary';
    }
    return 'default';
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={calsses.root}>
        <div className={calsses.header}>
          <IconButton onClick={prevMounth}>
            <NavigateBeforeIcon />
          </IconButton>
          <div>{moment(date).format('MMMM YYYY')}</div>
          <IconButton onClick={nextMounth}>
            <NavigateNextIcon />
          </IconButton>
        </div>
        <div className={calsses.week}>
          <div className={calsses.days}>пн</div>
          <div className={calsses.days}>вт</div>
          <div className={calsses.days}>ср</div>
          <div className={calsses.days}>чт</div>
          <div className={calsses.days}>пт</div>
          <div className={calsses.days}>сб</div>
          <div className={calsses.days}>вс</div>
        </div>
        {mapDays &&
          mapDays.map((week, idx) => (
            <div key={`${idx}${week}`} className={calsses.week}>
              {week.map((day, index) => (
                <div key={`${week}${day}${index}`} className={calsses.days}>
                  {day ? (
                    <Button
                      variant={checkVariant(day, `${idx}${index}${day}${mounth}`)}
                      color={checkColor(day, `${idx}${index}${day}${mounth}`)}
                      onClick={() => selectDate(`${idx}${index}${day}${mounth}`, mounth, day)}
                      className={calsses.btn}
                      size="small"
                    >
                      {day}
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
          ))}
        <div className={calsses.wrapBtn}>
          <Button color="secondary" onClick={handleClose}>
            Закрыть
          </Button>
          <Button color="primary" onClick={sendData}>
            Ок
          </Button>
        </div>
      </div>
    </Modal>
  );
};
