export interface TFilter {
  cat_id: string;
  from: string;
  to: string;
  channel: string;
  type: string;
  product: string;
  audience: string;
}
export interface TProduct {
  id: number;
  cat_id: number;
  code: string;
  product_type: string;
  name: string;
  color: string;
}
export interface TAtype {
  code: string;
  name: string;
  position: string;
  id: number;
}
export interface TUsers {
  id: number;
  cat_id: number;
  username: string;
  fio: string;
}
export interface TFilterSubdivisionItem {
  id: number;
  position: number;
  name: string;
  users: TUsers[];
  products: TProduct[];
}
export interface TFilterChannelItem {
  id: number;
  position: number;
  name: string;
}
export interface TFilterData {
  subdivision: TFilterSubdivisionItem[];
  channels: TFilterChannelItem[];
  amailtypes: TAtype[];
}
export interface TFilterState {
  readonly data: TFilterData | null;
  readonly dateInfo: { start: string; end: string } | null;
  subdivision: TFilterSubdivisionItem | null;
  subdivisionId: string;
  product: string;
  channel: string;
  type: string;
  audience: string;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export enum ActionTypes {
  GET_FILTER_R = '@@filter/GET_FILTER_R',
  GET_FILTER_S = '@@filter/GET_FILTER_S',
  GET_FILTER_E = '@@filter/GET_FILTER_E',

  SET_CHANEL = '@@filter/SET_CHANEL',
  SET_SUBDIVISION = '@@filter/SET_SUBDIVISION',
  SET_SUBDIVISION_ID = '@@filter/SET_SUBDIVISION_ID',
  SET_FILTER = '@@filter/SET_FILTER',
  SET_TYPE = '@@filter/SET_TYPE',
  SET_PRODUCT = '@@filter/SET_PRODUCT',
  SET_DATE_INFO = '@@filter/SET_DATE_INFO',
  SET_AUDIENCE = '@@filter/SET_AUDIENCE',

  CLEAN_UP = '@@filter/CLEAN_UP',
}
