import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import React from 'react';

interface PlaceForBlockProps {
  isEnable: boolean;
  position: number;
  index: number;
  onLeave: (value: null) => void;
  onDrop: (position: number, index: number) => void;
}

export const PlaceForBlock: React.FC<PlaceForBlockProps> = React.memo(({ position, index, isEnable, onLeave, onDrop }) => {
  return (
    <div className={`placeSet ${isEnable && 'placeSeting'}`} onMouseLeave={() => onLeave(null)} onMouseUp={() => onDrop(position, index)}>
      <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
        <SystemUpdateAltIcon />
      </div>
    </div>
  );
});
