import { Reducer } from 'redux';
import { ActionTypes, TConfigurationState } from './types';

export const initialState: TConfigurationState = {
  loading: true,
  data: null,
  errors: undefined,
};

const reducer: Reducer<TConfigurationState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONFIGURATION_R:
      return { ...state, loading: true, errors: undefined };
    case ActionTypes.GET_CONFIGURATION_S:
      return { ...state, loading: false, errors: undefined, data: action.payload };

    case ActionTypes.GET_CONFIGURATION_E:
      return { ...state, loading: false, errors: action.payload, data: null };
    default:
      return state;
  }
};

export { reducer as ConfigReducer };
