import { fork } from "redux-saga/effects";
import configSaga from "./configuration/sagas";
import eventsSaga from "./events/sagas";
import filterSaga from "./filter/sagas";
import userSaga from "./User/sagas";
import editSaga from "./editor/sagas";

export default function* Main() {
  yield fork(userSaga);
  yield fork(configSaga);
  yield fork(filterSaga);
  yield fork(eventsSaga);
  yield fork(editSaga);
}
