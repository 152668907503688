import Modal from '@material-ui/core/Modal';
import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import './BoxModal.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: '100%',
  },
}));

interface BoxModalProps {
  open: boolean;
  handleClose: () => void;
  cleanDraggedBlcks: (value: null) => void;
}

export const WarningModal: React.FC<BoxModalProps> = ({ open, handleClose, cleanDraggedBlcks }) => {
  const classes = useStyles();

  const handleClick = () => {
    cleanDraggedBlcks(null);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="boxModal-inner" style={{ minWidth: '300px' }}>
        <div className="boxBody" style={{ textAlign: 'center' }}>
          <h2>Вы действительно хотите начать сначала?</h2>
        </div>
        <div className="boxFooter">
          <div style={{ display: 'flex' }}>
            <Button variant="contained" onClick={handleClose}>
              нет
            </Button>
          </div>
          <Button color="primary" variant="contained" onClick={handleClick}>
            да
          </Button>
        </div>
      </div>
    </Modal>
  );
};
