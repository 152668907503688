import React from 'react';
import { TEditBlock } from '../../../store/editor/types';

interface SidebarItemProps {
  block: TEditBlock;
  startDrag: (e: React.MouseEvent, block: TEditBlock) => void;
  addBlock: (block: TEditBlock) => void;
}

export const SidebarItem: React.FC<SidebarItemProps> = React.memo(({ block, startDrag, addBlock }) => {
  const [timeoutId, setTimeoutId] = React.useState<any | undefined>();

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setTimeoutId(setTimeout(() => startDrag(e, block), 300));
  };

  const handleClick = (block: TEditBlock) => {
    addBlock(block);
    clearTimeout(timeoutId);
  };

  const repalceName = (str: string) => {
    if (str.includes('кнопка')) {
      return str.replaceAll(', кнопка', '') + ', кнопка';
    }
    return str;
  };

  return (
    <div className="sidebarItem" onMouseDown={handleMouseDown} onClick={() => handleClick(block)}>
      {repalceName(block.name)}
    </div>
  );
});
