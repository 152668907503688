import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import { ActionTypes, TEditState, TSaveTamplateR } from './types';

export const clearTemplate = (payload: null) => action(ActionTypes.CLEAR_TEMPLATE, payload);

const EditBlocksActions = new Actions('GET_EDIT_BLOCKS', ActionTypes);
const CopyTemplateActions = new Actions('COPY_TEMPLATE', ActionTypes);
const GetTemplateActions = new Actions('GET_TEMPLATE', ActionTypes);
const SeveTemplateActions = new Actions('SAVE_TEMPLATE', ActionTypes);
const DownloadTemplateActions = new Actions('DOWNLOAD_TEMPLATE', ActionTypes);

export interface TDataR extends TDefRequest {
  id: number;
}

export const getEditBlocks = {
  request: (payload: number) => EditBlocksActions.request(payload),
  success: (payload: TEditState['editBlocks']) => EditBlocksActions.success(payload || {}),
  error: (message: string) => EditBlocksActions.error(message),
};

export const setTamlate = {
  request: (payload: number) => CopyTemplateActions.request(payload),
  success: (payload: TEditState['template']) => CopyTemplateActions.success(payload || ''),
  error: (message: string) => CopyTemplateActions.error(message),
};

export const seveTamlate = {
  request: (payload: TSaveTamplateR) => SeveTemplateActions.request(payload),
  error: (message: string) => SeveTemplateActions.error(message),
};

export const showTemlate = {
  request: (payload: TDataR) => GetTemplateActions.request(payload),
  error: (message: string) => GetTemplateActions.error(message),
};

export const downloadTamlate = {
  request: (payload: TDataR) => DownloadTemplateActions.request(payload),
  error: (message: string) => DownloadTemplateActions.error(message),
};
