export const btnReplace = (str: string) => {
  const closeTag = '>';
  return str
    .split('class="btn-primary')
    .map((itm, i) => {
      if (i > 0) {
        const text = itm.replace(closeTag, `${closeTag}&#8203;&#8203;&#8203;`);
        return `class="btn-primary${text}`;
      }
      return itm;
    })
    .join('')
    .split('class="content-block')
    .map((itm, i) => {
      if (i > 0) {
        const text = itm.replace(closeTag, `${closeTag}&#8203;&#8203;&#8203;`);
        return `class="content-block${text}`;
      }
      return itm;
    })
    .join('');
};
