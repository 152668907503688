import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { TConfigTabAmailer, TConfigTabAtype, TConfigTabData } from '../../store/configuration/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '.MuiButtonBase-root': {
        padding: '5px !importent',
      },
    },
    wrapper: {
      background: '#f7f7f7',
    },
    heading: {
      fontSize: theme.typography.pxToRem(13),
      fontWeight: theme.typography.fontWeightRegular,
      margin: 0,
    },
    blockText: {
      background: '#3174ad',
      width: '100%',
      color: '#fff',
      borderRadius: '2px',
      margin: '2px 0',
      fontSize: theme.typography.pxToRem(12),
      padding: '2px 5px',
    },

    spm: {
      maxWidth: '90%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    select: {
      minHeight: '30px',

      '&.Mui-expanded': {
        minHeight: '30px',
      },
    },
  })
);

export const AccordionDefault: React.FC = () => {
  const classes = useStyles();
  const { Configurations, Filter } = useSelector((store: AppStore) => store);
  const [sideBarItems, setSideBarItems] = React.useState<number[]>([]);
  const [data, setData] = React.useState<TConfigTabData[] | null>(null);

  React.useEffect(() => {
    if (Configurations.data) {
      const data: TConfigTabData[] = [];
      Configurations.data.tabs.forEach((itm, i) => {
        if (String(Filter.type) !== '' && String(Filter.type) !== String(itm.code)) return null;
        if (!Filter.type && Filter.audience && Filter.audience !== 'no' && !String(itm.code).includes(Filter.audience)) return null;
        if (Filter.audience && Filter.audience === 'no' && String(itm.code).includes('client')) return null;
        data.push(itm);
      });
      setData(data);
    }
  }, [Configurations.data, Filter.audience, Filter.type]);

  React.useEffect(() => {
    const renderMail = (amailers: TConfigTabAmailer[], id: number) => {
      if (!amailers[0]) {
        return id;
      }

      if (!Filter.product) {
        const amaExist = amailers.filter((ama) => String(Filter.subdivision?.id) === String(ama.cat_id));
        if (!amaExist[0]) {
          return id;
        }
        return null;
      }

      const amaExist = amailers.filter(
        (ama) => String(ama.product_code) === String(Filter.product) && String(Filter.subdivision?.id) === String(ama.cat_id)
      );

      if (!amaExist[0]) return id;

      return null;
    };

    const renderTypes = (atypes: TConfigTabAtype[], id: number) => {
      if (atypes && Array.isArray(atypes)) {
        let newId = null;
        const atpExist = atypes.filter((atp) => String(atp.channel_id) === String(Filter.channel));

        if (!atpExist[0]) return (newId = id);

        atpExist.forEach((atp) => {
          newId = renderMail(atp.amailers, id);
        });

        return newId;
      }

      return id;
    };
    if (Configurations.data && Array.isArray(Configurations.data.tabs)) {
      const data = Configurations.data.tabs.map((tab) => renderTypes(tab.atypes, tab.id)).filter((id) => Number(id));
      setSideBarItems(data as number[]);
    }
  }, [Configurations.data, Filter.product, Filter.type, Filter.channel, Filter.subdivision]);

  const isArr = Configurations && Configurations.data && Configurations.data.tabs && Array.isArray(Configurations.data?.tabs);

  return (
    <div className={classes.root} id="external-events">
      {isArr &&
        data &&
        data.map((itm, i) => {
          return (
            <AccordionItem
              key={i}
              index={i}
              itm={itm}
              channel={Filter.channel}
              product={Filter.product}
              subdivisionId={Filter.subdivisionId}
              disabled={sideBarItems.includes(itm.id)}
            />
          );
        })}
    </div>
  );
};

interface AccordionItemProps {
  itm: TConfigTabData;
  index: number;
  disabled: boolean;
  product: string;
  subdivisionId: string;
  channel: string;
}

const AccordionItem: React.FC<AccordionItemProps> = React.memo(({ itm, index, disabled, channel, product, subdivisionId }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>('panel4');

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      className={classes.wrapper}
      expanded={expanded === itm.code}
      onChange={handleChange(itm.code)}
      TransitionProps={{ unmountOnExit: false }}
      disabled={disabled}
    >
      <AccordionSummary className={classes.select} expandIcon={<ExpandMoreIcon fontSize="small" />} aria-controls={`panel${index}-content`}>
        <Typography className={classes.heading}>{itm.name}</Typography>
      </AccordionSummary>
      <Details atypes={itm.atypes} channel={channel} product={product} subdivisionId={subdivisionId} />
    </Accordion>
  );
});

interface DetailsProps {
  atypes: TConfigTabAtype[];
  product: string;
  subdivisionId: string;
  channel: string;
}

const Details: React.FC<DetailsProps> = React.memo(({ atypes, product, subdivisionId, channel }) => {
  const classes = useStyles();
  const [data, setData] = React.useState<TConfigTabAtype[] | null>(null);

  const filterData = React.useCallback(
    (atypes: TConfigTabAtype[]) => {
      const atpExist = atypes.filter((atp) => String(atp.channel_id) === String(channel));
      if (!atpExist[0]) {
        return null;
      }
      return atpExist;
    },
    [channel]
  );

  React.useEffect(() => {
    setData(filterData(atypes));
  }, [filterData, atypes]);

  const isTextBlack = (color: string = '') => {
    const c = color.toLowerCase();

    switch (c) {
      case '#FFFF00'.toLowerCase():
      case '#00FF00'.toLowerCase():
      case '#d7df01'.toLowerCase():
      case '#ffd700'.toLowerCase():
      case '#00ff00'.toLowerCase():
      case '#ffffff'.toLowerCase():
      case '#fff'.toLowerCase():
        return true;
      default:
        return false;
    }
  };

  const renderAccordDetails = (atypes: TConfigTabAtype[] | null) => {
    if (!atypes) return null;
    return atypes.map((atp) => {
      const isInnerArr = atp.amailers && Array.isArray(atp.amailers);

      if (!isInnerArr) {
        console.log('amailers is not an array');
        return null;
      }

      return atp.amailers.map((amlr) => {
        if ((product && String(amlr.product_code) !== String(product)) || String(subdivisionId) !== String(amlr.cat_id)) return null;

        return (
          <div
            key={atp.type_id + '-' + amlr.id}
            style={{ background: amlr.mcolor }}
            id={atp.type_id + '-' + amlr.id}
            className={`${classes.blockText} fc-event`}
          >
            <span className={classes.spm} style={isTextBlack(amlr.mcolor) ? { color: 'black' } : {}}>
              {amlr.product_name || amlr.product_code}
            </span>
          </div>
        );
      });
    });
  };
  return (
    <AccordionDetails>
      <div style={{ width: '100%' }}>{renderAccordDetails(data)}</div>
    </AccordionDetails>
  );
});
