import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { getFilterS } from '../filter/actions';
import { TFilterData } from '../filter/types';
import * as Actions from './actions';
import { ActionTypes, TConfigData } from './types';

function* handleGetConfiguration(action: ReturnType<typeof Actions.getConfigurationR>): Generator {
  try {
    const data = yield call(callApi, { method: 'get', path: '/configuration' });

    yield put(Actions.getConfigurationS(data as TConfigData));
    const dataFilter = yield call(callApi, { method: 'get', path: '/filter' });
    yield put(getFilterS(dataFilter as TFilterData));
    action.payload.callBack && action.payload.callBack(true);
  } catch (e) {
    yield put(Actions.getConfigurationE(e));
    action.payload.callBack && action.payload.callBack(false);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_CONFIGURATION_R, handleGetConfiguration);
}

export default function* configSaga() {
  yield all([fork(watchFetchRequest)]);
}
