import { combineReducers } from "redux";
import { ConfigReducer } from "./configuration/reducer";
import { EventsReducer } from "./events/reducer";
import { FilterReducer } from "./filter/reducer";
import { UserReducer } from "./User/reducer";
import { EditReducer } from "./editor/reducer";

const rootReducer = combineReducers({
  User: UserReducer,
  Configurations: ConfigReducer,
  Filter: FilterReducer,
  Events: EventsReducer,
  Edit: EditReducer,
});

export default rootReducer;
