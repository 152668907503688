import { Reducer } from 'redux';
import { ActionTypes, TEventsState } from './types';

export const initialState: TEventsState = {
  loading: true,
  data: null,
  errors: undefined,
};

const reducer: Reducer<TEventsState> = (state = initialState, action): TEventsState => {
  switch (action.type) {
    case ActionTypes.DELETE_R:
    case ActionTypes.DUBLICATE_R:
    case ActionTypes.EDIT_R:
    case ActionTypes.CREATE_R:
    case ActionTypes.GET_R:
      return { ...state, loading: true, errors: undefined };
    case ActionTypes.CREATE_S:
      return {
        ...state,
        loading: false,
        data: state.data ? [...state.data, action.payload] : [action.payload],
      };
    case ActionTypes.DELETE_S:
    case ActionTypes.EDIT_S:
    case ActionTypes.GET_S:
      return { ...state, loading: false, data: action.payload };

    case ActionTypes.DUBLICATE_S:
      return { ...state, loading: false, data: state.data ? [...state.data, ...action.payload] : null };

    case ActionTypes.SUCCESS:
      return { ...state, loading: false };

    case ActionTypes.DELETE_E:
    case ActionTypes.DUBLICATE_E:
    case ActionTypes.EDIT_E:
    case ActionTypes.CREATE_E:
    case ActionTypes.GET_E:
      return { ...state, loading: false, errors: action.payload, data: null };
    default:
      return state;
  }
};

export { reducer as EventsReducer };
