import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { setTamlate, getEditBlocks, showTemlate, seveTamlate, downloadTamlate, TDataR } from './actions';
import { ActionTypes, TEditBlock, TSaveTamplateR } from './types';

function* handleGetEditBlocks(action: ReturnType<typeof getEditBlocks.request>): Generator {
  try {
    const id = action.payload;
    const data = (yield call(callApi, {
      method: 'get',
      path: `/mailing/blocks/${id}`,
    })) as { campaign_html: string; element_blocks: TEditBlock[] };
    yield put(setTamlate.success(data.campaign_html as string));
    yield put(getEditBlocks.success(data.element_blocks as TEditBlock[]));
  } catch (e) {
    yield put(getEditBlocks.error(e));
  }
}

function* getCopyTemplate(action: ReturnType<typeof setTamlate.request>): Generator {
  try {
    const id = action.payload;
    const data = (yield call(callApi, {
      method: 'get',
      path: `/mailing/blocks/${id}`,
    })) as { campaign_html: string; element_blocks: TEditBlock[] };
    yield put(setTamlate.success(data.campaign_html));
  } catch (e) {
    yield put(setTamlate.error(e));
  }
}

function* seveTemplate(action: ReturnType<typeof seveTamlate.request>): Generator {
  try {
    const { id, template } = action.payload as TSaveTamplateR;
    yield call(callApi, {
      method: 'post',
      path: `/mailing/campaign/${id}`,
      data: { campaign_html: template },
    });
  } catch (e) {
    yield put(seveTamlate.error(e));
  }
}

function* getTemplate(action: ReturnType<typeof showTemlate.request>): Generator {
  let success = true;
  let data: any = [];
  const { id, callBack } = action.payload as TDataR;
  try {
    data = yield call(callApi, {
      method: 'get',
      path: `/mailing/template/${id}`,
    });

    if (data) {
      const decodeTemplete = Buffer.from(data as string, 'base64').toString();
      const url = window.URL.createObjectURL(new Blob([decodeTemplete], { type: 'text/html' }));
      window.open(url, '_blank');
    }
  } catch (e) {
    success = false;
    yield put(showTemlate.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, data);
  }
}

function* downloadTemplate(action: ReturnType<typeof downloadTamlate.request>): Generator {
  let success = true;
  let data: any = [];
  const { id, callBack } = action.payload as TDataR;
  try {
    data = yield call(callApi, {
      method: 'get',
      path: `/mailing/template/${id}`,
    });
    if (data) {
      const decodeTemplete = Buffer.from(data as string, 'base64');
      const str = decodeTemplete.toString();
      const url = window.URL.createObjectURL(new Blob([str]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `template.html`);
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  } catch (e) {
    success = false;
    yield put(downloadTamlate.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, data);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_EDIT_BLOCKS_R, handleGetEditBlocks);
  yield takeEvery(ActionTypes.COPY_TEMPLATE_R, getCopyTemplate);
  yield takeEvery(ActionTypes.GET_TEMPLATE_R, getTemplate);
  yield takeEvery(ActionTypes.SAVE_TEMPLATE_R, seveTemplate);
  yield takeEvery(ActionTypes.DOWNLOAD_TEMPLATE_R, downloadTemplate);
}

export default function* editSaga() {
  yield all([fork(watchFetchRequest)]);
}
