import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Copyright } from '../components/Copyright';
import { MY_EMAIL, MY_PASS } from '../config';
import { getConfigurationR } from '../store/configuration/actions';
import { setAuth, setUser } from '../store/User/actions';
import { getToken } from '../utils/getToken';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Values {
  email: string;
  password: string;
}

export const Login: React.FC = () => {
  const [error, setError] = React.useState('');
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Увійти
        </Typography>
        <Formik
          initialValues={{
            email: MY_EMAIL,
            password: MY_PASS,
          }}
          validate={(values) => {
            const errors: Partial<Values> = {};
            if (!values.email) {
              errors.email = `Обов'зкове поле`;
            }
            if (!values.password) {
              errors.password = `Обов'зкове поле`;
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setError('');
            const data = await getToken(values.email, values.password);

            if (data.status) {
              return setError(data.detail?.message || 'Щось пiшло не так');
            }

            dispatch(
              setUser({
                cat_id: data.cat_id!,
                exp: data.exp!,
                fio: data.fio!,
                token: data.accessToken!,
                type: data.type!,
                username: data.username!,
                iat: data.iat!,
                id: data.id!,
              })
            );

            const callBack = (isOk: boolean) => {
              setSubmitting(false);
              dispatch(setAuth(isOk));
            };
            dispatch(getConfigurationR(callBack));
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className={classes.form}>
              <Field
                component={TextField}
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                label="Ваш Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Field
                component={TextField}
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
                onClick={submitForm}
                style={{ height: '36px' }}
              >
                {isSubmitting ? <CircularProgress size={15} /> : 'Увiйти'}
              </Button>
            </Form>
          )}
        </Formik>

        {error ? (
          <Alert severity="error" style={{ width: '100%' }}>
            <AlertTitle>Помилка</AlertTitle>
            {error}
          </Alert>
        ) : null}
      </div>
      <Box mt={3}>
        <Copyright />
      </Box>
    </Container>
  );
};
