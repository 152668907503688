import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { drawerWidth } from '../../../config';
import { AppStore } from '../../../store/applicationState';
import { downloadTamlate, showTemlate } from '../../../store/editor/actions';
import { setAuth, setUser } from '../../../store/User/actions';
import { SearchModal } from '../../modals';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    justifyContent: 'space-between',
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hoverBtn: {
    '&:hover': {
      background: 'rgba(0,0,0,0.2)',
    },
  },
  error: {
    position: 'absolute',
    top: '70px',
    right: '46%',
    color: 'red',
  },
}));

interface AppBarProps {
  id: number | null;
  saveTampalte: (id: number) => void;
}

export const EditorHeader: React.FC<AppBarProps> = React.memo(({ id, saveTampalte }) => {
  const { User } = useSelector((store: AppStore) => store);
  const [isModal, setIsModal] = React.useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    setIsModal(!isModal);
  };

  const downloadTemplate = () => {
    if (id) {
      dispatch(
        downloadTamlate.request({
          id,
        })
      );
    }
  };

  const showTemplate = () => {
    if (id) {
      dispatch(
        showTemlate.request({
          id,
        })
      );
    }
  };

  const classes = useStyles();

  return (
    <>
      <AppBar position="absolute" className={clsx(classes.appBar, classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <Box display="inline-flex" alignItems="center">
            <Button className={classes.hoverBtn} variant="outlined" color="inherit" onClick={handleClose}>
              Клонировать
            </Button>
            <Button
              className={classes.hoverBtn}
              variant="outlined"
              color="inherit"
              onClick={() => id && saveTampalte(id)}
              style={{ marginLeft: '20px' }}
            >
              Сохранить
            </Button>
            <Button className={classes.hoverBtn} variant="outlined" color="inherit" onClick={showTemplate} style={{ marginLeft: '20px' }}>
              Просмотр
            </Button>
            <Button className={classes.hoverBtn} variant="outlined" color="inherit" onClick={downloadTemplate} style={{ marginLeft: '20px' }}>
              Скачать
            </Button>
            <Button className={classes.hoverBtn} variant="outlined" color="inherit" style={{ marginLeft: '20px' }}>
              <a href="/email-constructor" style={{ textDecoration: 'none', color: 'white' }}>
                Календарь
              </a>
            </Button>
          </Box>
          <Box display="flex" alignItems="center">
            <AccountCircle />
            <Typography style={{ marginLeft: '10px' }}>{User.data?.fio || 'Нема данних'}</Typography>
            <Button
              className={classes.hoverBtn}
              variant="outlined"
              color="inherit"
              onClick={() => {
                localStorage.clear();
                dispatch(setAuth(false));
                dispatch(setUser(null));
              }}
              startIcon={<ExitToApp />}
              style={{ marginLeft: '20px' }}
            >
              Выйти
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      {isModal ? <SearchModal handleClose={handleClose} open={isModal} /> : null}
    </>
  );
});
