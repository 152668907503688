import React from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import CodeIcon from '@material-ui/icons/Code';
import { EditorBlock } from './EditorBlock';
import { TEditBlock } from '../../../store/editor/types';
import { PlaceForBlock } from './PlaceForBlock';
import { CodeMirror } from '../CodeMirror/CodeMirror';

const ZONE_UP = 0;
const ZONE_DOWN = 1;

interface BlockEditProps {
  isMove: boolean;
  isSiteBar: boolean;
  index: number;
  block: TEditBlock;
  scrollTop: number | null;
  lastIndex: number;
  setScrollTop: (value: number | null) => void;
  setIsMove: (isMove: boolean) => void;
  setIsDrop: (isMove: boolean) => void;
  handleeventDrop: (position: number, index: number) => void;
  changeBlock: (index: number, value: string) => void;
  removeDraggedBlock: (index: number) => void;
  copyDraggedBlock: (index: number, block: TEditBlock) => void;
  startDraggedBlock: (index: number, block: TEditBlock) => void;
}

export const BlockEdit: React.FC<BlockEditProps> = React.memo(
  ({
    index,
    lastIndex,
    block,
    isMove,
    scrollTop,
    isSiteBar,
    setScrollTop,
    setIsDrop,
    handleeventDrop,
    changeBlock,
    setIsMove,
    startDraggedBlock,
    copyDraggedBlock,
    removeDraggedBlock,
  }) => {
    const [codeMirror, setCodeMirror] = React.useState<number | null>(null);
    const [zoneUp, setZoneUp] = React.useState<number | null>(null);
    const [zoneDown, setZoneDown] = React.useState<number | null>(null);
    const [currenIndex, setCurrenIndex] = React.useState<number | null>(null);
    const [position, setPosition] = React.useState<{ x: number; y: number } | null>(null);
    const ref = React.useRef({});

    const handleDrop = React.useCallback(
      (position: number, index: number) => {
        setZoneUp(null);
        setZoneDown(null);
        setIsDrop(true);
        handleeventDrop(position, index);
      },
      [handleeventDrop, setIsDrop]
    );

    const changeValue = (index: number, value: string) => {
      changeBlock(index, value);
      codeMirror !== null && closeCodeMirror();
    };

    const closeCodeMirror = () => {
      setCodeMirror(null);
    };

    const changePosition = React.useCallback(
      (event: React.MouseEvent | MouseEvent) => {
        //@ts-ignore
        if (ref.current[currenIndex] && currenIndex !== null) {
          //@ts-ignore
          const clientHeight = ref.current[currenIndex].clientHeight;
          const offset = clientHeight / 2 + 60;
          let y = 0;
          if (scrollTop) {
            y = scrollTop + event.pageY - offset;
          } else {
            y = event.pageY - offset;
          }
          setPosition({ x: 0, y });
        }
      },
      [ref, currenIndex, scrollTop]
    );

    const mouseDown = (event: React.MouseEvent, index: number, block: TEditBlock) => {
      setCurrenIndex(index);
      setIsMove(true);
      startDraggedBlock(index, block);
      changePosition(event);
    };

    const mouseUp = React.useCallback(() => {
      setPosition({ x: 0, y: 0 });
      setCurrenIndex(null);
      setScrollTop(null);
    }, [setScrollTop]);

    const closeZoneDown = React.useCallback(() => {
      new Promise((resolve) => {
        let timeoutlId: NodeJS.Timeout;
        timeoutlId = setTimeout(() => {
          setZoneDown(null);
          resolve(timeoutlId);
        }, 200);
      }).then((timeoutlId: any) => clearTimeout(timeoutlId));
    }, []);

    React.useEffect(() => {
      currenIndex !== null && document.addEventListener('mousemove', changePosition);
      return () => document.removeEventListener('mousemove', changePosition);
    }, [changePosition, currenIndex]);

    React.useEffect(() => {
      document.addEventListener('mouseup', mouseUp);
      return () => document.removeEventListener('mouseup', mouseUp);
    }, [mouseUp]);

    return (
      <div
        //@ts-ignore
        ref={(istance) => (ref.current[index] = istance)}
        style={{
          top: currenIndex !== null && currenIndex === index && position ? `${position.y}px` : undefined,
          userSelect: 'none',
        }}
        className={`blockWrap ${currenIndex !== null && currenIndex === index && 'blockDraggable'}`}
      >
        <PlaceForBlock
          isEnable={zoneUp !== null && isSiteBar ? zoneUp === index : false}
          position={ZONE_UP}
          index={index}
          onDrop={() => null}
          onLeave={() => null}
        />
        <Line isEnable={zoneUp !== null && zoneUp === index && index === 0 && !isSiteBar} position={ZONE_UP} />
        <div className="dropZone">
          <DropZone index={index} position={ZONE_UP} onLeave={setZoneUp} onOver={(value) => isMove && setZoneUp(value)} onDrop={handleDrop} />
          <DropZone index={index} position={ZONE_DOWN} onLeave={closeZoneDown} onOver={(value) => isMove && setZoneDown(value)} onDrop={handleDrop} />
        </div>
        <div className="editContent">
          <div className="btnBlock btnRight">
            <div className="btnDelete">
              <IconButton aria-label="delete" onClick={() => removeDraggedBlock(index)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
          <div className="content" style={{ zIndex: isMove ? -1 : 1 }}>
            <EditorBlock html={block.html} changeTemplate={(value) => changeValue(index, value)} />
          </div>
          <div className="btnBlock btnLeft">
            <div className="btnWrap">
              <div className="btnChange">
                <IconButton aria-label="change" onMouseDown={(e) => mouseDown(e, index, block)}>
                  <OpenWithIcon />
                </IconButton>
              </div>
              <div className="btnCode">
                <IconButton aria-label="code" onClick={() => (codeMirror === null ? setCodeMirror(index) : closeCodeMirror())}>
                  <CodeIcon />
                </IconButton>
              </div>
              <div className="btnCopy">
                <IconButton aria-label="copy" onClick={() => copyDraggedBlock(index + 1, block)}>
                  <FilterNoneIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <Line isEnable={zoneDown !== null && zoneDown === index && !isSiteBar} position={ZONE_DOWN} />
        <PlaceForBlock
          isEnable={zoneDown !== null && isSiteBar && lastIndex === index ? zoneDown === index : false}
          position={ZONE_DOWN}
          index={index}
          onDrop={handleDrop}
          onLeave={closeZoneDown}
        />
        {codeMirror === index ? <CodeMirror html={block.html} doneEdit={(value) => changeValue(index, value)} closeCode={closeCodeMirror} /> : null}
      </div>
    );
  }
);

interface DropZoneProps {
  position: number;
  index: number;
  onOver: (index: number) => void;
  onLeave: (value: null) => void;
  onDrop: (position: number, index: number) => void;
}

const DropZone: React.FC<DropZoneProps> = ({ position, index, onOver, onLeave, onDrop }) => {
  return <div onMouseLeave={() => onLeave(null)} onMouseOver={() => onOver(index)} onMouseUp={() => onDrop(position, index)} />;
};

interface LineProps {
  isEnable: boolean;
  position: number;
}

const Line: React.FC<LineProps> = React.memo(({ position, isEnable }) => {
  return (
    <div
      className="line"
      style={{
        top: position === 0 ? '0' : undefined,
        bottom: position === 1 ? '0' : undefined,
        boxShadow: isEnable ? 'inset 0 4px #3f51b5' : 'inset 0 0 #3f51b5',
      }}
    />
  );
});
