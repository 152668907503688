import React from 'react';
import { TEditBlock } from '../../../store/editor/types';
import { BlockEdit } from './BlockEdit';
import { PlaceForBlock } from './PlaceForBlock';
import './BodyEditor.scss';
import { WarningModal } from '../../modals';

interface BodyEditorProps {
  draggedBlocks: TEditBlock[] | null;
  isMove: boolean;
  isSiteBar: boolean;
  scrollTop: number | null;
  setScrollTop: (value: number | null) => void;
  setIsMove: (value: boolean) => void;
  setIsDrop: (value: boolean) => void;
  handleeventDrop: (position: number, index: number) => void;
  changeBlock: (index: number, value: string) => void;
  removeDraggedBlock: (index: number) => void;
  copyDraggedBlock: (index: number, block: TEditBlock) => void;
  startDraggedBlock: (index: number, block: TEditBlock) => void;
  setDraggedBlocks: (blocks: TEditBlock[] | null) => void;
}

export const BodyEditor: React.FC<BodyEditorProps> = React.memo(
  ({
    draggedBlocks,
    isMove,
    isSiteBar,
    scrollTop,
    setScrollTop,
    setIsDrop,
    handleeventDrop,
    setDraggedBlocks,
    changeBlock,
    copyDraggedBlock,
    removeDraggedBlock,
    setIsMove,
    startDraggedBlock,
  }) => {
    const [dropZone, setDropZone] = React.useState<number | null>(null);
    const [isModal, setIsModal] = React.useState(false);

    const onDrop = React.useCallback(
      (position: number, index: number) => {
        setDropZone(null);
        setIsDrop(true);
        handleeventDrop(position, index);
      },
      [handleeventDrop, setIsDrop]
    );

    const showDropZone = () => {
      isMove && setDropZone(1);
    };

    const lastIndex = draggedBlocks ? draggedBlocks.length - 1 : 0;

    return (
      <>
        <div className="bodyEditor">
          <div className="editorArea">
            {draggedBlocks && draggedBlocks.length > 0 && (
              <div className="editHeader" onClick={() => setIsModal(!isModal)}>
                <div>Начать сначала</div>
              </div>
            )}
            {draggedBlocks && draggedBlocks.length > 0 ? (
              draggedBlocks.map((item, index) => (
                <div key={`${item}_${index}`}>
                  <BlockEdit
                    block={item}
                    index={index}
                    isMove={isMove}
                    lastIndex={lastIndex}
                    isSiteBar={isSiteBar}
                    scrollTop={scrollTop}
                    changeBlock={changeBlock}
                    copyDraggedBlock={copyDraggedBlock}
                    handleeventDrop={handleeventDrop}
                    removeDraggedBlock={removeDraggedBlock}
                    setIsDrop={setIsDrop}
                    setIsMove={setIsMove}
                    startDraggedBlock={startDraggedBlock}
                    setScrollTop={setScrollTop}
                  />
                  {draggedBlocks.length === 1 && isMove && !isSiteBar ? (
                    <PlaceForBlock isEnable={true} position={0} index={0} onLeave={setDropZone} onDrop={onDrop} />
                  ) : null}
                </div>
              ))
            ) : !dropZone ? (
              <div className="placeStartSet" onMouseOver={showDropZone}>
                <div>Перетяните сюда блок</div>
              </div>
            ) : (
              <PlaceForBlock isEnable={true} position={0} index={0} onLeave={setDropZone} onDrop={onDrop} />
            )}
          </div>
        </div>
        {isModal ? <WarningModal handleClose={() => setIsModal(!isModal)} open={isModal} cleanDraggedBlcks={setDraggedBlocks} /> : null}
      </>
    );
  }
);
