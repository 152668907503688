import { Reducer } from 'redux';
import { ActionTypes, TFilter, TFilterState } from './types';

export const initialState: TFilterState = {
  loading: true,
  data: null,
  subdivision: null,
  type: '',
  product: '',
  channel: '',
  subdivisionId: '',
  dateInfo: null,
  audience: '',
  errors: undefined,
};

const reducer: Reducer<TFilterState> = (state = initialState, action): TFilterState => {
  switch (action.type) {
    case ActionTypes.GET_FILTER_R:
      return { ...state, loading: true, errors: undefined };
    case ActionTypes.GET_FILTER_S:
      return { ...state, loading: false, errors: undefined, data: action.payload };
    case ActionTypes.SET_CHANEL:
      return { ...state, channel: action.payload };
    case ActionTypes.SET_SUBDIVISION:
      return { ...state, subdivision: action.payload };
    case ActionTypes.SET_SUBDIVISION_ID:
      return { ...state, subdivisionId: action.payload };
    case ActionTypes.SET_TYPE:
      return { ...state, type: action.payload, audience: '' };
    case ActionTypes.SET_AUDIENCE:
      return { ...state, audience: action.payload, type: '' };
    case ActionTypes.SET_PRODUCT:
      return { ...state, product: action.payload };
    case ActionTypes.SET_DATE_INFO:
      return { ...state, dateInfo: action.payload };
    case ActionTypes.SET_FILTER: {
      const { cat_id, channel, from, product, to, type, audience } = action.payload as TFilter;
      return { ...state, subdivisionId: cat_id, type, product, channel, dateInfo: { start: from, end: to }, audience };
    }

    case ActionTypes.GET_FILTER_E:
      return { ...state, loading: false, errors: action.payload, data: null };
    default:
      return state;
  }
};

export { reducer as FilterReducer };
