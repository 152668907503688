import { Reducer } from 'redux';
import { btnReplace } from '../../utils/btnReplace';
import { ActionTypes, TEditBlock, TEditState } from './types';

export const initialState: TEditState = {
  loading: true,
  editBlocks: null,
  error: undefined,
  template: null,
};

const reducer: Reducer<TEditState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CLEAR_TEMPLATE:
      return {
        ...state,
        template: action.payload,
      };
    case ActionTypes.COPY_TEMPLATE_S:
      return {
        ...state,
        loading: false,
        error: undefined,
        template: Buffer.from(action.payload, 'base64').toString(),
      };
    case ActionTypes.GET_EDIT_BLOCKS_S:
      const blocks = action.payload as TEditBlock[];
      return {
        ...state,
        loading: false,
        error: undefined,
        editBlocks: blocks.map((block) => {
          const html = Buffer.from(block.html, 'base64').toString();
          block.html = btnReplace(html);
          return block;
        }),
      };

    case ActionTypes.GET_TEMPLATE_R:
    case ActionTypes.DOWNLOAD_TEMPLATE_R:
    case ActionTypes.SAVE_TEMPLATE_R:
    case ActionTypes.GET_EDIT_BLOCKS_R:
    case ActionTypes.COPY_TEMPLATE_R:
      return { ...state, error: undefined };

    case ActionTypes.GET_TEMPLATE_E:
    case ActionTypes.DOWNLOAD_TEMPLATE_E:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.COPY_TEMPLATE_E:
      return {
        ...state,
        error: action.payload,
        tamplate: null,
      };
    case ActionTypes.GET_EDIT_BLOCKS_E:
      return {
        ...state,
        error: action.payload,
        editBlocks: null,
      };

    default:
      return state;
  }
};

export { reducer as EditReducer };
