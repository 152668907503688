export interface TEvent {
  id: number;
  title: string;
  description: string;
  cat_id: number;
  mag: string;
  start: Date;
  end: Date;
  allDay: string;
  color: string;
  url: string;
  category: string;
  repeat_type: string;
  user_id: number;
  repeat_id: number;
  mail_from: string;
  status: string;
  segment: string;
  segment_plus: string;
  group_ofsys: string;
  pc: number;
  type: string;
  type2: string;
  mail_id: string;
  preheader: string;
  a_cont: string;
  a_mail: string;
  mail_sys: string;
  ofsys_mail: string;
  ofsys_stat: string;
  sent: number;
  views: number;
  openr: string;
  clicks: number;
  clickr: string;
  demo: number;
  demo_cr: string;
  demo_green: number;
  demo_s: string;
  campaign_html: string;
  content: string;
  toden: number;
  level_id: number;
  channel_id: number;
}

export interface TEventsData {
  events: TEvent[];
}
export interface TEventsState {
  readonly data: TEvent[] | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export enum ActionTypes {
  GET_R = '@@events/GET_R',
  GET_S = '@@events/GET_S',
  GET_E = '@@events/GET_E',

  CREATE_R = '@@events/CREATE_R',
  CREATE_S = '@@events/CREATE_S',
  CREATE_E = '@@events/CREATE_E',

  DUBLICATE_R = '@@events/DUBLICATE_R',
  DUBLICATE_S = '@@events/DUBLICATE_S',
  DUBLICATE_E = '@@events/DUBLICATE_E',

  EDIT_R = '@@events/EDIT_R',
  EDIT_S = '@@events/EDIT_S',
  EDIT_E = '@@events/EDIT_E',

  DELETE_R = '@@events/DELETE_R',
  DELETE_S = '@@events/DELETE_S',
  DELETE_E = '@@events/DELETE_E',

  SUCCESS = '@@events/SUCCESS',

  CLEAN_UP = '@@events/CLEAN_UP',
}
