export interface TConfigTabAmailer {
  a_cont: number;
  a_mail: number;
  cat_id: number;
  group_ofsys: string;
  id: string;
  mail_from: string;
  mcolor: string;
  product_code: string;
  product_id: number;
  product_name: string;
  publication_code: number;
  segment_plus: string;
  type_code: string;
  type_id: number;
}
export interface TConfigTabAtype {
  channel_id: number;
  type_id: number;
  code: string;
  name: string;
  position: number;
  amailers: TConfigTabAmailer[];
}
export interface TConfigTabData {
  id: number;
  code: string;
  name: string;
  position: string;
  atypes: TConfigTabAtype[];
}
export interface TConfigData {
  tabs: TConfigTabData[];
}
export interface TConfigurationState {
  readonly data: TConfigData | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export enum ActionTypes {
  GET_CONFIGURATION_R = '@@configuration/GET_CONFIGURATION_R',
  GET_CONFIGURATION_S = '@@configuration/GET_CONFIGURATION_S',
  GET_CONFIGURATION_E = '@@configuration/GET_CONFIGURATION_E',

  CONFIGURATION_CLEAN_UP = '@@configuration/CONFIGURATION_CLEAN_UP',
}
