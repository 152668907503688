import { Actions, TDefRequest } from '../Actions';
import { ActionTypes, TEvent, TEventsData } from './types';

const EventsActions = new Actions('GET', ActionTypes);
export interface TGetEvents_R extends TDefRequest {
  cat_id: string;
  from: string;
  to: string;
}
export const GetEvents = {
  request: (payload: TGetEvents_R) => EventsActions.request(payload),
  success: (payload: TEventsData[]) => EventsActions.success(payload),
  error: (message: string) => EventsActions.error(message),
};

const CreateEventActions = new Actions('CREATE', ActionTypes);

export interface TCreateEvent_R extends TDefRequest {
  data: {
    title?: string;
    description?: string;
    amailers_id: string;
    cat_id: number;
    mag?: string;
    start: string;
    end: string;
    allDay?: string;
    color?: string;
    url?: string;
    category?: string;
    repeat_type?: string;
    user_id?: number;
    repeat_id?: number;
    mail_from?: string;
    status: string;
    segment?: string;
    segment_plus?: string;
    group_ofsys?: string;
    pc?: number;
    type?: string;
    type2?: string;
    mail_id?: string;
    preheader?: string;
    a_cont?: string;
    a_mail?: string;
    mail_sys?: string;
    ofsys_mail?: string;
    ofsys_stat?: string;
    sent?: number;
    views?: number;
    openr?: string;
    clicks?: number;
    clickr?: string;
    demo?: number;
    demo_cr?: string;
    demo_green?: number;
    demo_s?: string;
    campaign_html?: string;
    content?: string;
    toden?: number;
    level_id?: number;
    channel_id?: number;
  };
}
export const CreateEvent = {
  request: (payload: TCreateEvent_R) => CreateEventActions.request(payload),
  success: (payload: TCreateEvent_R['data']) => CreateEventActions.success(payload),
  error: (message: string) => CreateEventActions.error(message),
};

const DublicateEventActions = new Actions('DUBLICATE', ActionTypes);

export interface TDublicateEvent_R extends TDefRequest {
  data: {
    cat_id: number;
    dublicateId: number;
    dates: Date[];
  };
}
export const DublicateEvent = {
  request: (payload: TDublicateEvent_R) => DublicateEventActions.request(payload),
  success: (payload: TEvent[]) => DublicateEventActions.success(payload),
  error: (message: string) => DublicateEventActions.error(message),
};

const EditEventActions = new Actions('EDIT', ActionTypes);

export interface TEditEvent_R extends TDefRequest {
  data: {
    title: string;
    status: string;
    type2?: string;
    description?: string;
    preheader?: string;
    segment_plus?: string;
    group_ofsys?: string;
    mail_from?: string;
    a_cont?: string;
    a_mail?: string;
    start?: string;
    end?: string;
    mail_id?: string;
    ofsys_mail?: string;
    ofsys_stat?: string;
  };
  id: string;
}

export const EditEvent = {
  request: (payload: TEditEvent_R) => EditEventActions.request(payload),
  success: (payload: TEvent[]) => EditEventActions.success(payload),
  error: (message: string) => EditEventActions.error(message),
};

const DeleteEventActions = new Actions('DELETE', ActionTypes);

export interface TDeleteEvent_R extends TDefRequest {
  id: string;
}

export const DeleteEvent = {
  request: (payload: TDeleteEvent_R) => DeleteEventActions.request(payload),
  success: (payload: TEvent[]) => DeleteEventActions.success(payload),
  error: (message: string) => DeleteEventActions.error(message),
};
