import Modal from '@material-ui/core/Modal';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import './BoxModal.scss';
import { BoxEditMode } from './BoxEditMode';
import { BoxReadMode } from './BoxReadMode';
import { TEvent } from '../../store/events/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { DeleteEvent } from '../../store/events/actions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: '100%',
  },
}));

interface BoxModalProps {
  open: boolean;
  handleClose: () => void;
  box: TEvent;
  id: number | string;
}

export const BoxModal: React.FC<BoxModalProps> = ({ open, handleClose, box }) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = React.useState(false);
  const { Events, Filter } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const deleteHandler = () => {
    if (Events.loading) return null;
    const callBack = (success: boolean) => {
      if (!Events.data) return null;
      if (!success) return null;
      const newEvents = Events.data.filter((ev) => `${ev.id}` !== `${box.id}`);
      dispatch(DeleteEvent.success(newEvents));
      handleClose();
    };

    dispatch(
      DeleteEvent.request({
        id: `${box.id}`,
        callBack,
      })
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="boxModal-inner">
        {isEditMode && Filter.subdivision ? (
          <BoxEditMode
            box={box}
            product={Filter.subdivision.products.find((item) => item.code === box.mag)}
            closeHandler={handleClose}
            deleteHandler={deleteHandler}
          />
        ) : (
          <BoxReadMode event={box} closeHandler={handleClose} setIsEditMode={setIsEditMode} deleteHandler={deleteHandler} />
        )}
      </div>
    </Modal>
  );
};
