import React from 'react';
import { UnControlled as CodeMirr } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/dracula.css';
import 'codemirror/mode/xml/xml';
import './CodeMirror.scss';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

interface CodeMirrorProps {
  html: string;
  doneEdit: (value: string) => void;
  closeCode: () => void;
}

export const CodeMirror: React.FC<CodeMirrorProps> = ({ html, closeCode, doneEdit }) => {
  const [value, setValue] = React.useState<string | null>(null);

  return (
    <div className="codeWrapper">
      <div className="btnCodeMirr">
        <IconButton aria-label="done" style={{ color: value ? '#05e105' : 'grey' }} onClick={() => value && doneEdit(value)}>
          <DoneIcon />
        </IconButton>
        <IconButton aria-label="close" style={{ color: 'red' }} onClick={closeCode}>
          <CloseIcon />
        </IconButton>
      </div>
      <CodeMirr
        value={html}
        className="codeMirror"
        options={{
          mode: 'xml',
          theme: 'dracula',
          lineNumbers: true,
          lineWrapping: true,
        }}
        onChange={(editor, data, value) => setValue(value)}
      />
    </div>
  );
};
