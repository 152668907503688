import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import { Editor, Home, Login } from './pages';
import { AppStore } from './store/applicationState';
import { getConfigurationR } from './store/configuration/actions';
import { setAuth, setUser } from './store/User/actions';
import { checkToken } from './utils/checkToken';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderWrapper: {
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  })
);

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const { User } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classes = useStyles();

  React.useEffect(() => {
    const callBack = (isOk: boolean) => {
      dispatch(setAuth(isOk));
      setIsLoading(false);
    };

    (async () => {
      setIsLoading(true);
      const tokenData = await checkToken();
      if (tokenData.success) {
        dispatch(
          setUser({
            cat_id: tokenData.cat_id!,
            exp: tokenData.exp!,
            fio: tokenData.fio!,
            token: tokenData.token!,
            type: tokenData.type!,
            username: tokenData.username!,
            iat: tokenData.iat!,
            id: tokenData.id!,
          })
        );
        dispatch(getConfigurationR(callBack));
      } else {
        setIsLoading(false);
      }
    })();
  }, [dispatch]);

  if (isLoading)
    return (
      <div className={classes.loaderWrapper}>
        <CircularProgress color="secondary" />
      </div>
    );
  if (User.isAuth) {
    return (
      <Switch>
        <Route exact path="/editor/:id" component={Editor} />
        <Route path="/" component={Home} />
      </Switch>
    );
  }
  return (
    <Switch>
      <Route path="/" component={Login} />
    </Switch>
  );
}

export default App;
