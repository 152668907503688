import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CloseIcon from '@material-ui/icons/Close';
import { downloadTamlate, showTemlate } from '../../store/editor/actions';
import { useDispatch } from 'react-redux';
import { TEvent } from '../../store/events/types';

interface BoxReadModeProps {
  event: TEvent;
  closeHandler: () => void;
  setIsEditMode: (value: boolean) => void;
  deleteHandler: () => void;
}

export const BoxReadMode: React.FC<BoxReadModeProps> = ({ event, closeHandler, setIsEditMode, deleteHandler }) => {
  const dispatch = useDispatch();

  const showVisualTestMessage = () => {
    dispatch(
      showTemlate.request({
        id: event.id,
      })
    );
  };

  const showTemplate = () => {
    dispatch(
      showTemlate.request({
        id: event.id,
      })
    );
  };

  const downloadTemplate = () => {
    dispatch(
      downloadTamlate.request({
        id: event.id,
      })
    );
  };

  return (
    <>
      <div className="boxBody">
        <div className="boxHeader">
          <h2>{event.title}</h2>
          <div style={{ cursor: 'pointer', color: 'black' }} onClick={closeHandler}>
            <CloseIcon />
          </div>
        </div>
        <ul>
          <li>
            <b>Тема письма:</b> {event.title}
          </li>
          {event.channel_id === 1 && (
            <li>
              <b>Прехедер:</b> {event.preheader}
            </li>
          )}
          <li>
            <b>Комментарий:</b> {event.description}
          </li>
          <li>
            <b>Цель:</b> {event.category}
          </li>
          {event.channel_id === 1 && (
            <>
              <li>
                <b>Сегмент условие:</b> {event.segment_plus}
              </li>
              <li>
                <b>Ссылка на сегмент:</b> {event.group_ofsys}
              </li>
            </>
          )}
          <li>
            <b>Издание:</b> {event.mag}
          </li>
          <li>
            <b>Автор контента:</b> {event.a_cont}
          </li>
          <li>
            <b>Отправляет:</b> {event.a_mail}
          </li>
          <li>
            <b>Статус:</b> {event.status}
          </li>
        </ul>

        <ButtonGroup style={{ marginTop: '30px' }} size="small" aria-label="small outlined button group">
          <Button
            onClick={() => {
              window.open(`/#/editor/${event.id}`, '_blank');
            }}
          >
            HTML редактор письма
          </Button>
          <Button onClick={showVisualTestMessage}>Визуальный тест письма</Button>
          <Button onClick={showTemplate}> HTML код для sendsay</Button>
          <Button onClick={downloadTemplate}> Скачать HTML код</Button>
        </ButtonGroup>
      </div>
      <div className="boxFooter">
        <Button variant="contained" color="secondary" onClick={deleteHandler}>
          Удалить
        </Button>
        <Button color="primary" variant="contained" onClick={() => setIsEditMode(true)}>
          Изменить
        </Button>
      </div>
    </>
  );
};
