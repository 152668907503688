import { DropArg } from '@fullcalendar/interaction';
// import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { TConfigTabAmailer } from '../../store/configuration/types';
import { CreateEvent } from '../../store/events/actions';

export const useDrop = () => {
  const { Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const dropHandler = (data: DropArg) => {
    if (!Configurations.data || (Configurations.data && !Array.isArray(Configurations.data.tabs)))
      return alert('Помилка Configurations.data dropHandler');
    if (!data.draggedEl) return alert('Помилка draggedEl dropHandler');
    const id = data.draggedEl.id;
    if (!id) return alert('Помилка draggedEl не мае id dropHandler');
    let el: TConfigTabAmailer | undefined = undefined;

    const typeId = id.split('-')[0];
    const elId = id.split('-')[1];
    if (!typeId || !elId) return alert('Помилка draggedEl не мае typeId або elId dropHandler');

    Configurations.data.tabs.forEach((element) => {
      if (el) return null;
      if (!element.atypes || (element.atypes && !Array.isArray(element.atypes))) return console.error('element.atypes is not an array');
      element.atypes.forEach((tp) => {
        if (el) return null;
        el = tp.amailers.find((email) => `${email.id}` === `${elId}`);
      });
    });

    if (!el) return alert('Помилка ConfigTabAmailer');

    el = el as TConfigTabAmailer;

    dispatch(
      CreateEvent.request({
        data: {
          cat_id: el.cat_id,
          start: data.dateStr + ' 10:20:00',
          end: data.dateStr + ' 10:20:00',
          status: 'Планируется',
          amailers_id: el.id,
        },
      })
    );
  };

  return {
    dropHandler,
  };
};
