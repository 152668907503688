import { action } from 'typesafe-actions';
import { ActionTypes, TConfigData } from './types';

export const getConfigurationR = (callBack?: (isOk: boolean) => void) =>
  action(ActionTypes.GET_CONFIGURATION_R, { callBack });
export const getConfigurationS = (payload: TConfigData) =>
  action(ActionTypes.GET_CONFIGURATION_S, payload);
export const getConfigurationE = (message: string) =>
  action(ActionTypes.GET_CONFIGURATION_E, message);
// export const getUserS = (payload: TUser) => action(ActionTypes.GET_USER_S, payload);
// export const getUserE = (message: string) => action(ActionTypes.GET_USER_E, message);
