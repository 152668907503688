import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { setChanel, setSubdivision, setSubdivisionId } from '../../store/filter/actions';
import { TFilter } from '../../store/filter/types';
import { AppStore } from '../../store/applicationState';
import { setFilter } from '../../store/filter/actions';

export const useFilter = () => {
  const [initialDate, setInitialDate] = React.useState<Date | null>(null);
  const { Filter } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const { to } = queryString.parse(window.location.search.substr(1)) as unknown as TFilter;
    if (to) {
      let lastMonth = new Date(to);
      let date = new Date(lastMonth.setMonth(lastMonth.getMonth() - 1));
      setInitialDate(date);
    } else {
      setInitialDate(new Date());
    }
  }, []);

  React.useEffect(() => {
    if (Filter.data) {
      if (Filter.data.channels && !Filter.channel) {
        if (Filter.data.channels[0]) {
          dispatch(setChanel(String(Filter.data.channels[0].id)));
        }
      }

      if (Filter.data.subdivision) {
        if (Filter.subdivisionId) {
          const index = Filter.data.subdivision.findIndex((item) => String(item.id) === Filter.subdivisionId);
          if (Filter.data.subdivision[index]) {
            dispatch(setSubdivision(Filter.data.subdivision[index]));
          }
        } else if (Filter.data.subdivision[0]) {
          dispatch(setSubdivision(Filter.data.subdivision[0]));
          dispatch(setSubdivisionId(String(Filter.data.subdivision[0].id)));
        }
      }
    }
  }, [Filter.data, Filter.channel, Filter.subdivisionId, dispatch]);

  React.useEffect(() => {
    let filter = {} as TFilter;
    const { cat_id, from, to, channel, type, product, audience } = queryString.parse(window.location.search.substr(1)) as unknown as TFilter;
    filter.cat_id = cat_id || '';
    filter.from = from || '';
    filter.channel = channel || '';
    filter.to = to || '';
    filter.type = type || '';
    filter.product = product || '';
    if (!type) filter.audience = audience || '';
    if (filter.cat_id && filter.channel) dispatch(setFilter(filter));
  }, [dispatch]);

  React.useEffect(() => {
    let query = '';
    query += setPathCatId(Filter.subdivisionId);
    query += setPathDateInfo(Filter.dateInfo?.start, Filter.dateInfo?.end);
    query += setPathChannel(Filter.channel);
    query += setPathProduct(Filter.product);
    query += setPathType(Filter.type);
    if (!Filter.type) query += setPathAudience(Filter.audience);
    if (Filter.subdivisionId && Filter.channel) window.history.pushState({}, 'name', `/email-constructor?${query}`);
  }, [Filter.subdivisionId, Filter.channel, Filter.dateInfo, Filter.product, Filter.type, Filter.audience]);

  const setPathCatId = (cat_id: string | undefined) => {
    if (cat_id) return `cat_id=${cat_id}`;
    return '';
  };

  const setPathDateInfo = (from: string | undefined, to: string | undefined) => {
    if (from && to) return `&from=${from}&to=${to}`;
    return '';
  };

  const setPathChannel = (channel: string | undefined) => {
    if (channel) return `&channel=${channel}`;
    return '';
  };

  const setPathType = (type: string | undefined) => {
    if (type) return `&type=${type}`;
    return '';
  };

  const setPathProduct = (product: string | undefined) => {
    if (product) return `&product=${product}`;
    return '';
  };

  const setPathAudience = (audience: string | undefined) => {
    if (audience) return `&audience=${audience}`;
    return '';
  };

  return {
    initialDate,
  };
};
