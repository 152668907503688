import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import * as Actions from './actions';
import { ActionTypes, TEvent, TEventsData } from './types';

function* handleGetEvents(action: ReturnType<typeof Actions.GetEvents.request>): Generator {
  let success = true;
  let data: any = [];
  const { callBack, cat_id, from, to } = action.payload as Actions.TGetEvents_R;
  try {
    data = yield call(callApi, {
      method: 'get',
      path: `/events?cat_id=${cat_id}&from=${from}&to=${to}`,
    });

    yield put(Actions.GetEvents.success((data.events as TEventsData[]) || ([] as TEventsData[])));
  } catch (e) {
    yield put(Actions.GetEvents.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, data || []);
  }
}

function* handleCreateEvent(action: ReturnType<typeof Actions.CreateEvent.request>): Generator {
  let success = true;
  let respData: any = [];
  const { callBack, data } = action.payload as Actions.TCreateEvent_R;
  try {
    respData = yield call(callApi, {
      method: 'post',
      path: `/events`,
      data,
    });

    if (!respData.event) return null;

    yield put(Actions.CreateEvent.success(respData.event as Actions.TCreateEvent_R['data']));
  } catch (e) {
    yield put(Actions.CreateEvent.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, data || []);
  }
}

function* handleDublicateEvent(action: ReturnType<typeof Actions.DublicateEvent.request>): Generator {
  let success = true;
  const { callBack, data } = action.payload as Actions.TCreateEvent_R;
  try {
    const respData = (yield call(callApi, {
      method: 'post',
      path: `/events/dublicate`,
      data,
    })) as { events: TEvent[] };

    if (!respData.events) return null;

    yield put(Actions.DublicateEvent.success(respData.events));
  } catch (e) {
    yield put(Actions.DublicateEvent.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, data || []);
  }
}

function* editEvent(action: ReturnType<typeof Actions.EditEvent.request>): Generator {
  let success = true;
  const { callBack, data, id } = action.payload as Actions.TEditEvent_R;
  let respData = null as unknown as { event: TEvent };
  try {
    respData = (yield call(callApi, {
      method: 'patch',
      path: `/events/${id}`,
      data,
    })) as { event: TEvent };
    yield put({
      type: ActionTypes.SUCCESS,
    });
  } catch (e) {
    yield put(Actions.EditEvent.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success, respData.event || null);
  }
}
function* deleteEvent(action: ReturnType<typeof Actions.DeleteEvent.request>): Generator {
  let success = true;
  const { callBack, id } = action.payload as Actions.TDeleteEvent_R;
  try {
    yield call(callApi, {
      method: 'delete',
      path: `/events/${id}`,
    });
    yield put({
      type: ActionTypes.SUCCESS,
    });
  } catch (e) {
    yield put(Actions.DeleteEvent.error(e));
  } finally {
    if (!callBack) return null;
    yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_R, handleGetEvents);
  yield takeEvery(ActionTypes.CREATE_R, handleCreateEvent);
  yield takeEvery(ActionTypes.DUBLICATE_R, handleDublicateEvent);
  yield takeEvery(ActionTypes.EDIT_R, editEvent);
  yield takeEvery(ActionTypes.DELETE_R, deleteEvent);
}

export default function* eventsSaga() {
  yield all([fork(watchFetchRequest)]);
}
