import React from 'react';
import { TEditBlock } from '../../store/editor/types';

export const useManageBlocks = () => {
  const [draggedBlocks, setDraggedBlocks] = React.useState<TEditBlock[] | null>(null);
  const [currentBlock, setCurrentBlock] = React.useState<TEditBlock | null>(null);
  const [isMove, setIsMove] = React.useState(false);
  const [isDrop, setIsDrop] = React.useState(false);
  const [isSiteBar, setIsSiteBar] = React.useState<boolean>(false);

  const handleeventDrop = React.useCallback(
    (position: number, index: number) => {
      setDraggedBlocks((prevState) => {
        if (prevState && currentBlock) {
          const copytedBlocks = [...prevState];
          if (currentBlock.changePalces) {
            // eslint-disable-next-line
            const blocks = copytedBlocks.filter((item, idx) => {
              if (currentBlock.id !== item.id || currentBlock.index !== idx) {
                return item;
              }
            });
            let i: number;
            if (position) {
              i = index + 1;
              if (currentBlock.index !== undefined && currentBlock.index < i) {
                i = index;
              }
            } else {
              i = index;
            }
            blocks.splice(i, 0, currentBlock);
            return blocks;
          }
          const i = position ? index + 1 : index;
          copytedBlocks.splice(i, 0, { ...currentBlock, changePalces: true });
          return copytedBlocks;
        } else if (currentBlock) {
          return [{ ...currentBlock, changePalces: true }];
        }
        return prevState;
      });
      setCurrentBlock(null);
    },
    [currentBlock, setDraggedBlocks]
  );

  const addBlock = React.useCallback((block: TEditBlock) => {
    setDraggedBlocks((prevState) => {
      if (prevState) {
        return [...prevState, block];
      }
      return [block];
    });
  }, []);

  const removeDraggedBlock = React.useCallback(
    (index: number) => {
      if (draggedBlocks) {
        const blocks = draggedBlocks.filter((item, i) => i !== index);
        const draggedB = blocks.length > 0 ? blocks : null;
        setDraggedBlocks(draggedB);
      }
    },
    [draggedBlocks]
  );

  const startDraggedBlock = React.useCallback(
    (index: number, block: TEditBlock) => {
      setCurrentBlock({ ...block, index });
    },
    [setCurrentBlock]
  );

  const copyDraggedBlock = React.useCallback((index: number, block: TEditBlock) => {
    setDraggedBlocks((prevState) => {
      if (prevState) {
        const copyBlocks = [...prevState];
        copyBlocks.splice(index, 0, block);
        return copyBlocks;
      }
      return prevState;
    });
  }, []);

  const changeBlock = React.useCallback((index: number, value: string) => {
    setDraggedBlocks((prevState) => {
      if (prevState) {
        return prevState.map((block, idx) => {
          if (idx === index) {
            return { ...block, html: value };
          }
          return block;
        });
      }
      return prevState;
    });
  }, []);

  return {
    isMove,
    isDrop,
    currentBlock,
    draggedBlocks,
    isSiteBar,
    setIsSiteBar,
    setIsMove,
    setIsDrop,
    handleeventDrop,
    removeDraggedBlock,
    startDraggedBlock,
    copyDraggedBlock,
    setCurrentBlock,
    setDraggedBlocks,
    changeBlock,
    addBlock,
  };
};
