import { action } from 'typesafe-actions';
import { ActionTypes, TFilterData, TFilterSubdivisionItem, TFilterState, TFilter } from './types';

export const getFilterR = (callBack?: (isOk: boolean) => void) => action(ActionTypes.GET_FILTER_R, { callBack });
export const getFilterS = (payload: TFilterData) => action(ActionTypes.GET_FILTER_S, payload);
export const getFilterE = (message: string) => action(ActionTypes.GET_FILTER_E, message);

export const setChanel = (chanel: string) => action(ActionTypes.SET_CHANEL, chanel);
export const setSubdivision = (subdivision: TFilterSubdivisionItem) => action(ActionTypes.SET_SUBDIVISION, subdivision);
export const setSubdivisionId = (subdivisionId: string) => action(ActionTypes.SET_SUBDIVISION_ID, subdivisionId);
export const setProduct = (product: string) => action(ActionTypes.SET_PRODUCT, product);
export const setType = (type: string) => action(ActionTypes.SET_TYPE, type);
export const setAudience = (audience: string) => action(ActionTypes.SET_AUDIENCE, audience);
export const setFilter = (filter: TFilter) => action(ActionTypes.SET_FILTER, filter);
export const setDateInfo = (dateInfo: TFilterState['dateInfo']) => action(ActionTypes.SET_DATE_INFO, dateInfo);
