import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface EditorBlockProps {
  html: string;
  changeTemplate: (value: string) => void;
}

const OFFSET = 3;

export const EditorBlock: React.FC<EditorBlockProps> = ({ html, changeTemplate }) => {
  const textConfig = {
    height: 500,
    language: 'ru',
    menubar: false,
    inline: true,
    plugins: ['image code', 'imagetools', 'quickbars', 'noneditable', 'link', 'noneditable', 'paste'],
    toolbar: false,
    imagetools_toolbar: false,
    image_description: false,
    image_dimensions: false,
    link_title: false,
    link_quicklink: false,
    default_link_target: '_blank',
    target_list: false,
    visual: false,
    automatic_uploads: true,
    quickbars_image_toolbar: 'imageoptions',
    quickbars_selection_toolbar: 'bold italic | alignleft aligncenter alignright',
    paste_as_text: true,
    content_style: `
      *[contentEditable="true"]:focus,
      *[contentEditable="true"]:hover {
        outline: none;
      }
      .mce-resizehandle {
        display: none;
      }
      .mce-content-body audio[data-mce-selected], .mce-content-body embed[data-mce-selected], .mce-content-body img[data-mce-selected], .mce-content-body object[data-mce-selected], .mce-content-body table[data-mce-selected], .mce-content-body video[data-mce-selected] {
        outline: none;
      }
      .tox-notifications-container {
        display: none;
      }
      img {
        max-width: 100%;
        height: auto;
      }
      a {
        color: #348eda;
      }
    `,
    setup: function (editor: any) {
      const checkClassBtn = (str: string) => {
        if (!str) return false;
        if (str.includes('btn-primary') || str === 'content-block') return true;
        return false;
      };
      const getLastNode = (node: any): any => {
        if (node.childNodes[0]) {
          return getLastNode(node.childNodes[0]);
        }
        return node;
      };
      const changeRng = () => {
        const node = editor.selection.getNode();
        const range = editor.selection.getRng();
        if (range.startOffset < OFFSET) {
          let firstNode: any;
          let lastNode: any;
          if (node.className.includes('btn-primary')) {
            const index = node.childNodes.length - 1;
            firstNode = getLastNode(node.childNodes[0]);
            lastNode = getLastNode(node.childNodes[index]);
          }
          if (node.className === 'content-block') {
            const index = node.childNodes.length - 1;
            firstNode = getLastNode(node.childNodes[0]);
            lastNode = getLastNode(node.childNodes[index]);
          }
          if (firstNode && lastNode) {
            const start = OFFSET > firstNode.length ? 1 : OFFSET;
            const end = range.endOffset > lastNode.length ? lastNode.length : range.endOffset <= OFFSET ? lastNode.length : range.endOffset;
            range.setStart(firstNode, start);
            range.setEnd(lastNode, end);
            editor.selection.setRng(range);
          }
        }
      };
      editor.on('keydown', (node: any) => {
        if (node.keyCode === 8 || node.keyCode === 46) {
          const node = editor.selection.getNode();
          const text = node.innerText;
          if (text.length <= OFFSET && checkClassBtn(node.className)) {
            return false;
          }
        }
      });
      editor.on('dblclick', () => {
        const node = editor.selection.getNode();
        if (node.nodeName.toLowerCase() === 'a' && node.href && checkClassBtn(node.className)) {
          const textNode = node.childNodes[0];
          const range = editor.selection.getRng();
          range.setStart(textNode, OFFSET);
          range.setEnd(textNode, textNode.length);
        }
      });
      editor.ui.registry.addContextToolbar('contextformtogglebutton', {
        predicate: (node: any) => {
          if (checkClassBtn(node.className)) {
            try {
              changeRng();
            } catch (e) {
              console.log(e);
            }
          }
          return (node.nodeName.toLowerCase() === 'a' && node.href) || !editor.selection.isCollapsed();
        },
        items: 'link',
        position: 'selection',
      });
    },
  };

  return (
    <Editor
      onEditorChange={(value, editor) => {
        changeTemplate(editor.getContent());
      }}
      value={html}
      inline={true}
      init={textConfig}
    />
  );
};
