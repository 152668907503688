import React from 'react';
import moment from 'moment';
import FullCalendar, { EventApi, EventDropArg } from '@fullcalendar/react';
import ruLocale from '@fullcalendar/core/locales/ru';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CodeIcon from '@material-ui/icons/Code';
import { EventInput } from '@fullcalendar/core';
import { BoxModal } from './modals';
import { useMainCalendar } from './hooks/useMainCalendar';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../store/applicationState';
import { EditEvent, GetEvents } from '../store/events/actions';
import { TEvent } from '../store/events/types';
import { useDrop } from './hooks/useDrop';
import SearchIcon from '@material-ui/icons/Search';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import CheckIcon from '@material-ui/icons/Check';
import { setDateInfo } from '../store/filter/actions';
import './MainCalendar.scss';
import { CreateIcon } from './svgs/IconCreate';
import SvgIcon from '@material-ui/core/SvgIcon';

interface EventCompProps {
  event: EventApi;
}

const EventComp: React.FC<EventCompProps> = ({ event }) => {
  let icon = (
    <SvgIcon style={{ fontSize: '12px' }} fontSize="small">
      <CreateIcon />
    </SvgIcon>
  );

  if (event.extendedProps.status === 'Верстка') {
    icon = <CodeIcon style={{ fontSize: '12px' }} fontSize="small" />;
  }
  if (event.extendedProps.status === 'Отправлен тест') {
    icon = <SearchIcon style={{ fontSize: '12px' }} fontSize="small" />;
  }
  if (event.extendedProps.status === 'Согласовано') {
    icon = <ThumbUpAltOutlinedIcon style={{ fontSize: '12px' }} fontSize="small" />;
  }
  if (event.extendedProps.status === 'Отправлено') {
    icon = <CheckIcon style={{ fontSize: '12px' }} fontSize="small" />;
  }
  const t = moment(event.startStr).format('H:mm');

  return (
    <Box
      display="flex"
      alignItems="center"
      id={event.id}
      style={{
        paddingLeft: '5px',
        paddingRight: '5px',
        borderRadius: '2px',
        background: event.backgroundColor,
        border: `1px solid ${
          event.backgroundColor.includes('white') || event.backgroundColor.includes('fff') || event.backgroundColor.includes('255, 255, 255')
            ? 'rgb(220, 20, 60)'
            : 'transparent'
        }`,
        color:
          event.backgroundColor.includes('white') || event.backgroundColor.includes('fff') || event.backgroundColor.includes('255, 255, 255')
            ? 'rgb(220, 20, 60)'
            : 'white',
        width: '100%',
      }}
    >
      {icon}
      <Typography
        style={{
          fontSize: '10px',
          marginLeft: '5px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: '90%',
          textOverflow: 'ellipsis',
        }}
      >
        {t} {event.title}
      </Typography>
    </Box>
  );
};

interface MainCalendarProps {
  initialDate: Date | null;
}

export const MainCalendar: React.FC<MainCalendarProps> = ({ initialDate }) => {
  const { Filter, Events } = useSelector((store: AppStore) => store);
  const { closeBox, openBox, box } = useMainCalendar();
  const dispatch = useDispatch();
  const { dropHandler } = useDrop();
  const [startEv, setStartEv] = React.useState('');
  const [channel, setChannel] = React.useState('');

  React.useEffect(() => {
    if (Filter.subdivisionId && Filter.dateInfo?.start && Filter.dateInfo?.end) {
      dispatch(
        GetEvents.request({
          cat_id: Filter.subdivisionId,
          from: Filter.dateInfo.start,
          to: Filter.dateInfo.end,
        })
      );
    }
  }, [dispatch, Filter.subdivisionId, Filter.dateInfo]);

  const firstLetterToUppercase = (str: string) => {
    const first = str[0].toUpperCase();
    return first + str.substr(1);
  };

  React.useEffect(() => {
    if (Filter.channel && Filter.data) {
      const chanel = Filter.data.channels.find((channel) => String(channel.id) === Filter.channel);
      if (chanel && chanel.id !== 1) {
        const str = firstLetterToUppercase(chanel.name);
        const word = str
          .split('-')
          .map((item, i) => {
            if (i !== 0) {
              return firstLetterToUppercase(item);
            }
            return item;
          })
          .join('');
        setChannel(word);
      } else {
        setChannel('');
      }
    }
  }, [Filter.channel, Filter.data]);

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    const draggableEl = document.getElementById('external-events');
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: '.fc-event',

        longPressDelay: 1,
        eventData: function (eventEl) {
          let title = eventEl.querySelector('span')?.innerText;
          return {
            title,
            backgroundColor: eventEl.style.backgroundColor,
            create: false,
          };
        },
      });
    }
  }, []);

  const handleeventDrop = (eventDropInfo: EventDropArg) => {
    const title = eventDropInfo.event.title
      .split('|')
      // eslint-disable-next-line
      .map((item, i) => {
        if (i === 2) {
          const title = item.substr(1);
          return title;
        }
        if (i > 2) {
          return `|${item}`;
        }
      })
      .filter((item) => item !== null)
      .join('');
    dispatch(
      EditEvent.request({
        id: eventDropInfo.event.id,
        data: {
          title: title,
          status: eventDropInfo.event.extendedProps.status,
          start: moment(eventDropInfo.event.startStr).format('YYYY-MM-DD HH:mm:ss'),
          end: moment(eventDropInfo.event.startStr).format('YYYY-MM-DD HH:mm:ss'),
        },
        callBack: (success: boolean, event: TEvent) => {
          if (!Events.data) return null;
          if (!success) return null;
          if (!event) return null;
          const oldEvents = Events.data.filter((item) => item.id !== event.id);
          dispatch(EditEvent.success([...oldEvents, event]));
        },
      })
    );
  };

  const checkEvent = React.useCallback(
    (event: TEvent) => {
      if (Filter.subdivision?.id !== event.cat_id) return false;
      if (event.category.includes('hollyday')) return true;
      if (`${Filter.channel}` !== `${event.channel_id}`) return false;
      if (Filter.audience) {
        if (Filter.audience === 'no') {
          if (event.category.includes('client')) return false;
        } else if (!event.category.includes(Filter.audience) && !Filter.type) return false;
      }
      if (Filter.type) {
        if (event.category !== `${Filter.type}${channel}`) return false;
      }
      if (Filter.product) {
        if (event.mag !== Filter.product) return false;
      }
      return true;
    },
    [Filter.subdivision, Filter.channel, Filter.product, Filter.type, Filter.audience, channel]
  );

  const getEventsHandler = React.useCallback(
    (
      fetchInfo: {
        start: Date;
        end: Date;
        startStr: string;
        endStr: string;
        timeZone: string;
      },
      successCallback: (events: EventInput[]) => void,
      _failureCallback: (error: any) => void
    ) => {
      const start = moment(fetchInfo.start).format('YYYY-MM-DD');
      const end = moment(fetchInfo.end).format('YYYY-MM-DD');
      if (start !== startEv) {
        setStartEv(start);
        dispatch(setDateInfo({ start, end }));
      } else if (Events.data && Events.data[0]) {
        const newEvents: EventInput[] = [];
        Events.data.forEach((event: TEvent) => {
          if (checkEvent(event)) {
            let type2 = event.category;
            if (event.type2) {
              type2 += `_${event.type2}`;
            }
            newEvents.push({
              id: `${event.id}`,
              title: `${event.mag} | ${type2} | ${event.title}`,
              start: event.start,
              end: event.end,
              extendedProps: {
                status: event.status,
              },
              backgroundColor: event.color,
            });
          }
        });
        successCallback(newEvents);
      }
    },
    [dispatch, checkEvent, startEv, Events.data]
  );

  if (!initialDate) {
    return null;
  }

  return (
    <>
      <div style={{ width: '100%', minHeight: '900px', height: '100vh' }}>
        <FullCalendar
          eventDrop={handleeventDrop}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          }}
          locale={ruLocale}
          weekNumbers
          weekNumberFormat={{
            week: 'short',
          }}
          droppable
          height="100%"
          initialDate={initialDate}
          events={getEventsHandler}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror
          dayMaxEvents={true}
          eventContent={(data) => <EventComp event={data.event} />}
          eventClick={(e) => openBox(e)}
          drop={dropHandler}
        />
      </div>
      {box ? <BoxModal box={box} handleClose={closeBox} id="" open /> : null}
    </>
  );
};
