import { EventClickArg } from '@fullcalendar/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { TEvent } from '../../store/events/types';

export const useMainCalendar = () => {
  const { Events } = useSelector((store: AppStore) => store);
  const [box, setBox] = React.useState<TEvent | null>(null);
  const closeBox = () => {
    setBox(null);
  };

  const openBox = (data: EventClickArg) => {
    if (!Events.data) return console.error('No events data');

    const eventId = data.event._def.publicId;

    const event = Events.data.find((ev) => `${ev.id}` === `${eventId}`);

    if (!event) return console.error('Could not find event');

    setBox(event);

    // console.log(title, 'title');
    // setBoxTitle(title);
    // setIsBoxOpen(true);
  };

  return {
    openBox,
    closeBox,
    box,
  };
};
