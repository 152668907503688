import Modal from '@material-ui/core/Modal';
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import './BoxModal.scss';
import { useDispatch } from 'react-redux';
import { setTamlate } from '../../store/editor/actions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: '100%',
  },
}));

interface BoxModalProps {
  open: boolean;
  handleClose: () => void;
}

export const SearchModal: React.FC<BoxModalProps> = ({ open, handleClose }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch();

  const searchTemplate = () => {
    const num = Number(value);
    const id = num ? (Number.isNaN(num) ? null : num) : null;
    if (id) {
      dispatch(setTamlate.request(id));
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="boxModal-inner">
        <div className="boxBody">
          <h2>Загрузить верстку</h2>
          <TextField
            label="ID розсилки:"
            placeholder="ID розсилки"
            fullWidth
            margin="normal"
            multiline
            value={value}
            onChange={(e) => setValue(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="boxFooter">
          <div style={{ display: 'flex' }}>
            <Button variant="contained" onClick={handleClose} style={{ minWidth: '118px', height: '36px' }}>
              Отменить
            </Button>
          </div>
          <Button color="primary" variant="contained" onClick={searchTemplate}>
            ок
          </Button>
        </div>
      </div>
    </Modal>
  );
};
