import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { drawerWidth } from '../config';
import { AppStore } from '../store/applicationState';
import { setAudience, setChanel, setFilter, setProduct, setSubdivisionId, setType } from '../store/filter/actions';
import { setAuth, setUser } from '../store/User/actions';
import { useHeader } from './hooks/useHeader';
import { SelectDefault } from './ui';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    justifyContent: 'space-between',
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hoverBtn: {
    '&:hover': {
      background: 'rgba(0,0,0,0.2)',
    },
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  selectWrapper: {
    width: '110px',
    marginRight: theme.spacing(1),

    '& > div > label': {
      // transform: 'translate(0, 1.5px) scale(0.75)',
      // transformOrigin: 'top left',
    },
  },
}));

interface AppBarProps {
  title: string;
  open: boolean;
  openDrawer: (value: boolean) => void;
  onRender: () => void;
}

export const Header: React.FC<AppBarProps> = ({ title, open, openDrawer, onRender }) => {
  const { User } = useSelector((store: AppStore) => store);
  const { chanelOptions, typeOptions, subdivisionOptions, Filter, products } = useHeader();
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} onTransitionEnd={onRender}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => openDrawer(true)}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Box display="inline-flex" alignItems="center">
          <Box className={classes.selectWrapper}>
            <SelectDefault
              value={String(Filter.subdivisionId || '')}
              handleChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                const sub = Filter.data?.subdivision.find((s) => String(s.id) === String(event.target.value));
                if (sub) {
                  dispatch(setSubdivisionId(String(sub.id)));
                  dispatch(setProduct(''));
                } else {
                  console.log('No such subdivision');
                }
              }}
              options={subdivisionOptions}
              title="Направление"
            />
          </Box>
          <Box className={classes.selectWrapper}>
            <SelectDefault
              value={String(Filter.channel || '')}
              handleChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                dispatch(setChanel(event.target.value as string));
              }}
              options={chanelOptions}
              title="Каналы"
            />
          </Box>{' '}
          <Box className={classes.selectWrapper}>
            <SelectDefault
              value={Filter.type || ''}
              handleChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                dispatch(setType(event.target.value as string));
              }}
              options={typeOptions}
              title="Тип письма"
            />
          </Box>{' '}
          <Box className={classes.selectWrapper}>
            <SelectDefault
              value={Filter.audience || ''}
              handleChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                dispatch(setAudience(event.target.value as string));
              }}
              options={[
                { value: '', label: 'Все' },
                { value: 'client', label: 'Подписчики' },
                // { value: 'project', label: 'Проекты' },
                { value: 'no', label: 'Холод' },
              ]}
              title="Аудитория"
            />
          </Box>{' '}
          <Box className={classes.selectWrapper}>
            <SelectDefault
              value={Filter.product || ''}
              handleChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                dispatch(setProduct(event.target.value as string));
              }}
              options={products}
              title="Издание"
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCircle />
          <Typography style={{ marginLeft: '10px' }}>{User.data?.fio || 'Нема данних'}</Typography>
          <Button
            className={classes.hoverBtn}
            variant="outlined"
            color="inherit"
            onClick={() => {
              localStorage.clear();
              dispatch(setAuth(false));
              dispatch(setUser(null));
              dispatch(
                setFilter({
                  audience: '',
                  cat_id: '',
                  channel: '',
                  from: '',
                  product: '',
                  to: '',
                  type: '',
                })
              );
              window.history.pushState({}, 'name', `/email-constructor`);
            }}
            startIcon={<ExitToApp />}
            style={{ marginLeft: '20px' }}
          >
            Выйти
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
