import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';

interface SelectOpt {
    value: string;
    id: number;
    label: string;
}

export const useHeader = () => {
    const { Filter } = useSelector((store: AppStore) => store);

    const [chanelOptions, setChanelOptions] = React.useState<SelectOpt[]>([]);
    const [typeOptions, setTypeOptions] = React.useState<SelectOpt[]>([]);
    const [subdivisionOptions, setSubdivisionOptions] = React.useState<SelectOpt[]>([]);
    const [products, setProducts] = React.useState<SelectOpt[]>([]);
    React.useEffect(() => {
        const setToChanel = () => {
            if (Filter.data!.channels && Array.isArray(Filter.data!.channels)) {
                const options: SelectOpt[] = [];
                Filter.data!.channels.forEach((itm) => {
                    options.push({
                        value: String(itm.id),
                        id: itm.id,
                        label: itm.name,
                    });
                });

                setChanelOptions(options);
            }
        };
        const setToType = () => {
            if (Filter.data!.amailtypes && Array.isArray(Filter.data!.amailtypes)) {
                const options: SelectOpt[] = [];
                options.push({ value: '', id: 9999, label: 'Все типы' });

                Filter.data!.amailtypes.forEach((itm) => {
                    options.push({
                        value: String(itm.code),
                        id: itm.id,
                        label: itm.name,
                    });
                });

                setTypeOptions(options);
            }
        };

        const setToSubdivision = () => {
            if (Filter.data!.subdivision && Array.isArray(Filter.data!.subdivision)) {
                const options: SelectOpt[] = [];
                // options.push({ value: '9999', id: 9999, label: 'Всi типи' });

                Filter.data!.subdivision.forEach((itm) => {
                    options.push({
                        value: String(itm.id),
                        id: itm.id,
                        label: itm.name,
                    });
                });

                setSubdivisionOptions(options);
            }
        };

        if (Filter.data) {
            setToChanel();
            setToType();
            setToSubdivision();
        }
    }, [Filter.data]);

    React.useEffect(() => {
        const setToProducts = () => {
            if (Filter.subdivision?.products && Array.isArray(Filter.subdivision.products)) {
                const options: SelectOpt[] = [];
                options.push({ value: '', id: 9999, label: 'Все' });

                Filter.subdivision.products.forEach((itm) => {
                    options.push({
                        value: String(itm.code),
                        id: itm.id,
                        label: itm.name,
                    });
                });

                // console.log(options, 'options');
                setProducts(options);
            }
        };

        if (Filter.subdivision) {
            setToProducts();
        }
    }, [Filter.subdivision]);
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        console.log(event.target.value as string);
    };

    return {
        handleChange,
        chanelOptions,
        typeOptions,
        subdivisionOptions,
        Filter,
        products,
    };
};
