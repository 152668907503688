import jwt_decode from 'jwt-decode';
import { TOKEN } from '../config';
import { callApi } from './callApi';

const cash: {
  [key: string]: {
    exp?: number;
    fio?: string;
    id?: number;
    type?: string;
    username?: string;
    iat?: number;
  };
} = {};

export interface ReturnType {
  success: boolean;
  token: string;
  exp?: number;
  fio?: string;
  id?: number;
  cat_id?: number[];
  type?: string;
  username?: string;
  iat?: number;
}

const returnFailObj = {
  success: false,
  token: '',
};

const returnFail = (): ReturnType => {
  localStorage.clear();
  return returnFailObj;
};

const refresh = async (): Promise<ReturnType> => {
  const refreshToken = localStorage.getItem(TOKEN.refresh);
  if (!refreshToken) return returnFail();

  const decodeToken: {
    exp?: number;
    iat?: number;
  } = jwt_decode(refreshToken || '');

  if (!decodeToken) {
    console.log('Token can not be decoded');
    return returnFail();
  }

  if (!decodeToken.exp) {
    console.log('Token expired');
    return returnFail();
  }
  const isExpired = Date.now() >= decodeToken.exp * 1000;

  if (isExpired) {
    return returnFail();
  }

  try {
    const data = await callApi({
      path: '/auth/refresh-tokens',
      method: 'post',
      refreshToken,
      data: {
        refreshToken,
      },
    });

    if (!data.accessToken || !data.refreshToken) {
      return returnFail();
    }
    const decodeAccessToken: {
      exp?: number;
      iat?: number;
    } = jwt_decode(data.accessToken || '');

    if (!decodeAccessToken) {
      console.log('checkToken.ts decodeAccessToken', 11);
      return returnFail();
    }

    if (!decodeAccessToken.exp) {
      console.log('checkToken.ts decodeAccessToken', 22);
      return returnFail();
    }

    cash[data.accessToken] = decodeAccessToken;

    localStorage.setItem(TOKEN.access, data.accessToken);
    localStorage.setItem(TOKEN.refresh, data.refreshToken);

    console.log('Token was decoded and refreshed checkToken.ts');

    return {
      success: true,
      token: data.accessToken,
      ...decodeAccessToken,
    };
  } catch (e) {
    return returnFail();
  }
};

export async function checkToken(): Promise<ReturnType> {
  const accessToken = localStorage.getItem(TOKEN.access);
  if (!accessToken) return returnFail();
  const token = accessToken.split(' ')[1];
  let decodeToken: {
    at_id?: string;
    authorized?: boolean;
    exp?: number;
    iat?: number;
    usr_id?: string;
  } = {};

  if (cash[token]) {
    console.log('checkToken.ts token was got from cash');
    decodeToken = cash[token];
  } else {
    decodeToken = jwt_decode(token || '');
  }

  if (!decodeToken) {
    console.log('checkToken.ts', 1);
    return returnFail();
  }

  if (!decodeToken.exp) {
    console.log('checkToken.ts', 2);
    return returnFail();
  }

  const isExpired = Date.now() >= decodeToken.exp * 1000;

  if (isExpired) {
    delete cash[token];
    console.log('checkToken.ts', 3);
    const result = await refresh();
    return result;
    // return returnFail();
  }

  if (!cash[token]) {
    console.log('Set cash');
    cash[token] = decodeToken;
  }

  return {
    success: true,
    token: accessToken,
    ...decodeToken,
  };
}
