import React from 'react';
import { TEditBlock } from '../../../store/editor/types';
import './SidebarEditor.scss';
import { SidebarItem } from './SidebarItem';

interface SidebarEditorProps {
  blocks?: TEditBlock[] | null;
  clientWigth: number | null;
  isMove: boolean;
  isDrop: boolean;
  setIsSiteBar: (value: boolean) => void;
  setIsMove: (value: boolean) => void;
  setIsDrop: (value: boolean) => void;
  setCurrentBlock: (blocks: TEditBlock | null) => void;
  addBlock: (block: TEditBlock) => void;
}

export const SidebarEditor: React.FC<SidebarEditorProps> = ({
  blocks,
  clientWigth,
  isMove,
  isDrop,
  setIsSiteBar,
  setIsDrop,
  setIsMove,
  setCurrentBlock,
  addBlock,
}) => {
  const [dragBlock, setDragBlock] = React.useState<TEditBlock | null>(null);
  const [startPosition, setStartPosition] = React.useState<{ x: number; y: number } | null>(null);
  const [position, setPosition] = React.useState<{ x: number; y: number } | null>(null);

  const mouseMove = React.useCallback(
    (event: MouseEvent | React.MouseEvent) => {
      const positionX = event.clientX - 100;
      const maxPositionRight = event.clientX + 150;
      const x = clientWigth && maxPositionRight > clientWigth ? clientWigth - 237 : positionX;
      const y = event.clientY - 30;
      setPosition({ x, y });
      !startPosition && setStartPosition({ x: 16, y });
    },
    [startPosition, clientWigth]
  );

  const startDrag = React.useCallback(
    (e: React.MouseEvent, block: TEditBlock) => {
      mouseMove(e);
      setCurrentBlock(block);
      setDragBlock(block);
      setIsMove(true);
      setIsSiteBar(true);
    },
    [mouseMove, setCurrentBlock, setIsMove, setIsSiteBar]
  );

  const endDrag = React.useCallback(() => {
    setPosition(startPosition);
    setStartPosition(null);
    setIsMove(false);
    setIsSiteBar(false);
  }, [startPosition, setIsMove, setIsSiteBar]);

  const clearAll = () => {
    setDragBlock(null);
    setPosition(null);
    setStartPosition(null);
    setIsDrop(false);
    setIsMove(false);
    setIsSiteBar(false);
  };

  React.useEffect(() => {
    if (isDrop) {
      setDragBlock(null);
      setIsDrop(false);
    }
  }, [isDrop, setIsDrop]);

  React.useEffect(() => {
    isMove && document.addEventListener('mousemove', mouseMove);
    return () => document.removeEventListener('mousemove', mouseMove);
  }, [mouseMove, isMove]);

  React.useEffect(() => {
    isMove && document.addEventListener('mouseup', endDrag);
    return () => document.removeEventListener('mouseup', endDrag);
  }, [endDrag, isMove]);

  return (
    <div className="sidebarEditor">
      {dragBlock && (
        <div
          className="sidebarItemDrag"
          style={{
            left: position ? `${position.x}px` : undefined,
            top: position ? `${position.y}px` : undefined,
            transition: !isDrop && !isMove ? 'all 500ms ease-out' : undefined,
          }}
          onTransitionEnd={clearAll}
        >
          <SidebarItem block={dragBlock} startDrag={() => null} addBlock={() => null} />
        </div>
      )}
      {blocks && <SidebarBlocks blocks={blocks} startDrag={startDrag} addBlock={addBlock} />}
    </div>
  );
};

interface SidebarBlocksProps {
  blocks: TEditBlock[];
  startDrag: (e: React.MouseEvent, block: TEditBlock) => void;
  addBlock: (block: TEditBlock) => void;
}

const SidebarBlocks: React.FC<SidebarBlocksProps> = React.memo(({ blocks, startDrag, addBlock }) => {
  return (
    <>
      {blocks.map((item) => (
        <SidebarItem key={item.id} block={item} startDrag={startDrag} addBlock={addBlock} />
      ))}
    </>
  );
});
