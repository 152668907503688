export interface TEditBlock {
  id: number;
  sort: number;
  mag: string;
  name: string;
  html: string;
  changePalces?: boolean;
  index?: number;
}

export interface TEditState {
  readonly editBlocks: TEditBlock[] | null;
  readonly template: string | null;
  readonly loading: boolean;
  readonly error?: string | undefined;
}

export interface TSaveTamplateR {
  id: number;
  template: string;
}

export enum ActionTypes {
  GET_EDIT_BLOCKS_R = '@@editor/GET_EDIT_BLOCKS_R',
  GET_EDIT_BLOCKS_S = '@@editor/GET_EDIT_BLOCKS_S',
  GET_EDIT_BLOCKS_E = '@@editor/GET_EDIT_BLOCKS_E',

  COPY_TEMPLATE_R = '@@editor/COPY_TEMPLATE_R',
  COPY_TEMPLATE_S = '@@editor/COPY_TEMPLATE_S',
  COPY_TEMPLATE_E = '@@editor/COPY_TEMPLATE_E',

  GET_TEMPLATE_R = '@@editor/GET_TEMPLATE_R',
  GET_TEMPLATE_S = '@@editor/GET_TEMPLATE_S',
  GET_TEMPLATE_E = '@@editor/GET_TEMPLATE_E',

  SAVE_TEMPLATE_R = '@@editor/SAVE_TEMPLATE_R',
  SAVE_TEMPLATE_S = '@@editor/SAVE_TEMPLATE_S',
  SAVE_TEMPLATE_E = '@@editor/SAVE_TEMPLATE_E',

  DOWNLOAD_TEMPLATE_R = '@@editor/DOWNLOAD_TEMPLATE_R',
  DOWNLOAD_TEMPLATE_S = '@@editor/DOWNLOAD_TEMPLATE_S',
  DOWNLOAD_TEMPLATE_E = '@@editor/DOWNLOAD_TEMPLATE_E',

  CLEAR_TEMPLATE = '@@editor/CLEAR_TEMPLATE',
  EDIT_BLOCKS_CLEAN_UP = '@@editor/EDIT_BLOCKS_CLEAN_UP',
}
