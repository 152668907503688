import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TEvent } from '../../store/events/types';
import { useDispatch, useSelector } from 'react-redux';
import { DublicateEvent, EditEvent } from '../../store/events/actions';
import { AppStore } from '../../store/applicationState';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { TProduct } from '../../store/filter/types';
import { MultiDatesPicker } from '../ui/MultiDatesPicker';

interface BoxEditModeProps {
  box: TEvent;
  product: TProduct | undefined;
  closeHandler: () => void;
  deleteHandler: () => void;
}

export const BoxEditMode: React.FC<BoxEditModeProps> = ({ box, product, closeHandler, deleteHandler }) => {
  const { Events, Filter } = useSelector((store: AppStore) => store);
  const [title, setTitle] = React.useState(box.title);
  const [type2, setType2] = React.useState(box.type2);
  const [status, setStatus] = React.useState(box.status);
  const [description, setDescription] = React.useState(box.description);
  const [preheader, setPreheader] = React.useState(box.preheader);
  const [segmentPlus, setSegmentPlus] = React.useState(box.segment_plus);
  const [groupOfsys, setGroupOfsys] = React.useState(box.group_ofsys);
  const [mailFrom, setMailFrom] = React.useState(box.mail_from ? box.mail_from : product?.name);
  const [aCount, setACount] = React.useState(box.a_cont);
  const [aMail, setAMail] = React.useState(box.a_mail);
  const [date, setDate] = React.useState(moment(box.start).format('MM/DD/yyyy'));
  const [time, setTime] = React.useState(moment(box.start).format('HH:mm'));
  const [mailId, setMailId] = React.useState(box.mail_id);
  const [ofsysMail, setOfsysMail] = React.useState(box.ofsys_mail);
  const [ofsysStat, setOfsysStat] = React.useState(box.ofsys_stat);
  const [isCalendar, setIsCalendar] = React.useState(false);
  const dispatch = useDispatch();

  const createHandler = (dates: Date[] | []) => {
    if (Events.loading || dates.length === 0) return null;
    dispatch(
      DublicateEvent.request({
        data: {
          cat_id: box.cat_id,
          dublicateId: box.id,
          dates,
        },
        callBack: () => {
          closeHandler();
        },
      })
    );
  };

  const saveHandler = () => {
    if (Events.loading) return null;
    let fieldsForEmail = {};
    if (box.channel_id === 1) {
      fieldsForEmail = {
        preheader,
        segment_plus: segmentPlus,
        group_ofsys: groupOfsys,
        mail_from: mailFrom,
      };
    }
    const dateTime = moment(`${date} ${time}`, 'MM/DD/yyyy HH:mm').format('YYYY-MM-DD HH:mm:ss');
    dispatch(
      EditEvent.request({
        id: `${box.id}`,
        data: {
          title,
          status,
          type2,
          description,
          a_cont: aCount,
          a_mail: aMail,
          start: dateTime,
          end: dateTime,
          mail_id: mailId,
          ofsys_mail: ofsysMail,
          ofsys_stat: ofsysStat,
          ...fieldsForEmail,
        },
        callBack: (success: boolean, event: TEvent) => {
          if (!Events.data) return null;
          if (!success) return null;
          if (!event) return null;
          const oldEvents = Events.data.filter((item) => item.id !== event.id);
          dispatch(EditEvent.success([...oldEvents, event]));
          closeHandler();
        },
      })
    );
  };

  return (
    <>
      <div className="boxBody">
        <h2>{title}</h2>
        <div className="boxBody-content">
          <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
            <div className="boxBody-row">
              <div className="boxBody-row-item">
                <span>Цель рассылки:</span>
                <Select value={box.category} onChange={(e) => console.log(e)} disabled>
                  <MenuItem value={box.category}>{box.category}</MenuItem>
                </Select>
              </div>
              <div className="boxBody-row-item">
                <span>Издание:</span>
                <Select value={box.mag} onChange={(e) => console.log(e)} disabled>
                  <MenuItem value={box.mag}>{box.mag}</MenuItem>
                </Select>
              </div>

              <div className="boxBody-row-item">
                <span>Уточнение:</span>
                <TextField
                  placeholder="Уточнение"
                  fullWidth
                  value={type2}
                  onChange={(e) => setType2(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="boxBody-row-item">
                <span>Статус:</span>
                <Select value={status} onChange={(e) => setStatus(e.target.value as string)}>
                  <MenuItem value={'Планируется'}>Планируется</MenuItem>
                  <MenuItem value={'Верстка'}>Верстка</MenuItem>
                  <MenuItem value={'Отправлен тест'}>Отправлен тест</MenuItem>
                  <MenuItem value={'Согласовано'}>Согласовано</MenuItem>
                  <MenuItem value={'Отправлено'}>Отправлено</MenuItem>
                </Select>
              </div>
            </div>
            <TextField
              label="Тема рассылки:"
              placeholder="Тема рассылки"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
              style={{ marginTop: '30px' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {box.channel_id === 1 && (
              <>
                <TextField
                  label="Сегмент условие:"
                  placeholder="Сегмент условие"
                  fullWidth
                  value={segmentPlus}
                  onChange={(e) => setSegmentPlus(e.target.value)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Ссылка на сегмент:"
                  placeholder="Ссылка на сегмент"
                  fullWidth
                  value={groupOfsys}
                  onChange={(e) => setGroupOfsys(e.target.value)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Прехедер:"
                  placeholder="прехедер"
                  fullWidth
                  margin="normal"
                  value={preheader}
                  onChange={(e) => setPreheader(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="От кого:"
                  placeholder="От кого"
                  fullWidth
                  value={mailFrom}
                  onChange={(e) => setMailFrom(e.target.value)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
            <TextField
              label="Описание:"
              placeholder="Описание"
              fullWidth
              margin="normal"
              multiline
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className="boxBody-row" style={{ marginTop: '10px' }}>
              <div className="boxBody-row-item">
                <span>Кто готовит ТЗ:</span>
                <Select value={aCount} onChange={(e) => setACount(e.target.value as string)}>
                  {Filter.subdivision?.users.map((user) => (
                    <MenuItem key={user.id} value={user.fio}>
                      {user.fio}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="boxBody-row-item">
                <span>Кто отправляет:</span>
                <Select value={aMail} onChange={(e) => setAMail(e.target.value as string)}>
                  {Filter.subdivision?.users.map((user) => (
                    <MenuItem key={user.id} value={user.fio}>
                      {user.fio}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="boxBody-row-item">
                <span>День отправки:</span>
                <KeyboardDatePicker
                  disableToolbar
                  style={{ margin: 0 }}
                  value={date}
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  onChange={(date: Date | null) => setDate(moment(date).format('MM/DD/yyyy'))}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>

              <div className="boxBody-row-item">
                <span>Время отправки:</span>
                <TextField
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </div>
            </div>
            <div className="boxBody-row" style={{ marginTop: '30px' }}>
              <div className="boxBody-row-item" style={{ width: '33%' }}>
                <span>UTM campaign:</span>
                <TextField
                  placeholder="UTM campaign"
                  fullWidth
                  value={mailId}
                  onChange={(e) => setMailId(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="boxBody-row-item" style={{ width: '33%' }}>
                <span>ID верстки:</span>
                <TextField
                  placeholder="ID верстки"
                  fullWidth
                  value={ofsysMail}
                  onChange={(e) => setOfsysMail(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="boxBody-row-item" style={{ width: '33%' }}>
                <span>ID статистики:</span>
                <TextField
                  placeholder="ID статистики"
                  fullWidth
                  value={ofsysStat}
                  onChange={(e) => setOfsysStat(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <div className="boxFooter">
        <div style={{ display: 'flex' }}>
          <Button color="secondary" variant="contained" style={{ marginRight: '20px', minWidth: '118px', height: '36px' }} onClick={deleteHandler}>
            {Events.loading ? <CircularProgress color="inherit" size={15} /> : 'Удалить'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: '20px', minWidth: '118px', height: '36px' }}
            onClick={() => setIsCalendar(true)}
          >
            {Events.loading ? <CircularProgress color="inherit" size={15} /> : 'Дублировать'}
          </Button>
          <Button color="primary" variant="contained" onClick={saveHandler} style={{ minWidth: '118px', height: '36px' }}>
            {Events.loading ? <CircularProgress color="inherit" size={15} /> : 'Сохранить'}
          </Button>
        </div>
        <Button color="secondary" variant="contained" onClick={closeHandler}>
          Закрыть
        </Button>
      </div>
      {isCalendar && <MultiDatesPicker open={isCalendar} handleClose={() => setIsCalendar(false)} onDone={createHandler} />}
    </>
  );
};
