import axios from 'axios';
import { API_URL } from '../config';
import { checkToken, ReturnType } from './checkToken';

export async function callApi(payload: {
  method: 'get' | 'post' | 'put' | 'delete' | 'patch';
  path: string;
  data?: Object | null;
  refreshToken?: string;
}) {
  const { method, path, data, refreshToken } = payload;

  const mustCheckToken = path !== '/login' && !refreshToken;
  let userToken: ReturnType = {
    success: true,
    token: '',
  };

  if (mustCheckToken) {
    userToken = await checkToken();
  }

  try {
    const res = await axios.request({
      method,
      headers: {
        Authorization: userToken.token,
      },
      url: API_URL + path,
      data: data ? data : null,
    });
    return res.data;
  } catch (e) {
    console.log(e.response, 'Error Api Call');
    console.log('Error', e);
    let error = e;
    if (e.response && e.response.data) error = e.response.data;
    throw error;
  }
}
